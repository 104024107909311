// src/Cr80CardLayout.js
import React from "react";
import QrCodeDisplay from "./QrCodeDisplay";
import sonic from "../components/images/Sonic.png";

/**
 * Front side of the CR80 card:
 *  - SCAN OR TAP
 *  - The QR code with a Sonic logo
 *  - "TO TAKE OUR SURVEY!" below
 */
function Cr80CardLayout({
  qrLink,
  size = 140,
  fgColor = "#000000",
  ecLevel = "H",
  qrStyle = "dots",
  eyeColor = [
    { outer: "#137AC9", inner: "#E40046" },
    { outer: "#137AC9", inner: "#E40046" },
    { outer: "#137AC9", inner: "#E40046" },
  ],
  eyeRadius = {
    topLeft: 20,
    topRight: 20,
    bottomLeft: 20,
    bottomRight: 20,
  },
}) {
  // Build the final link that includes ?tagId={uniqueCode}
  const finalQrLink = `https://truemetric.io/?tagId=${qrLink}`;

  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: "46%", 
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <div
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            marginBottom: 0,
            zIndex: 99999,
          }}
        >
          SCAN OR TAP
        </div>

        <QrCodeDisplay
          qrLink={finalQrLink}
          size={size}
          bgColor="rgba(255, 255, 255, 0)"
          fgColor={fgColor}
          ecLevel={ecLevel}
          qrStyle={qrStyle}
          eyeColor={eyeColor}
          eyeRadius={eyeRadius}
          logoUrl={sonic}
          logoWidth={40}
          removeQrBg={false}
        />

        <div
          style={{
            fontSize: "15px",
            fontWeight: "bold",
            marginTop: -15,
            zIndex: 9999,
          }}
        >
          TO TAKE OUR SURVEY!
        </div>
      </div>
    </div>
  );
}

export default Cr80CardLayout;
