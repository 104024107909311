import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LoadingIndicator from '../LoadingIndicator';

// Third-party circular progress bar
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

// Local CSS module (for bigCircle, tooltip, etc.)
import styles from './TZv2Stores.module.css';

// React Icons
import { FiPrinter } from 'react-icons/fi';

// Chakra UI
import {
  Box,
  Flex,
  Text,
  Heading,
  IconButton,
  Divider,
  SimpleGrid,
  Switch,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';

import IncompletesModal from './IncompletesModal';

/**
 * Return a user-friendly objective label
 */
function getObjectiveLabel(title) {
  const lower = title.toLowerCase();
  if (lower.includes('system initiative')) return 'System Initiative';
  if (lower.includes('lto')) return 'LTO';
  if (lower.includes('live free l!ve')) return 'LIVE FREE L!VE';
  return title;
}

/**
 * Convert an ISO timestamp to Central Time in a "December 19th at 11:38 PM" format
 */
function formatCentralTime(isoString) {
  if (!isoString) return '';
  const dateObj = new Date(isoString);

  function ordinalSuffixOf(day) {
    const j = day % 10;
    const k = day % 100;
    if (j === 1 && k !== 11) return `${day}st`;
    if (j === 2 && k !== 12) return `${day}nd`;
    if (j === 3 && k !== 13) return `${day}rd`;
    return `${day}th`;
  }

  const month = dateObj.toLocaleString('en-US', {
    month: 'long',
    timeZone: 'America/Chicago',
  });
  const dayNumber = parseInt(
    dateObj.toLocaleString('en-US', {
      day: 'numeric',
      timeZone: 'America/Chicago',
    }),
    10
  );
  const dayWithSuffix = ordinalSuffixOf(dayNumber);
  const timeString = dateObj.toLocaleString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZone: 'America/Chicago',
  });

  return `${month} ${dayWithSuffix} at ${timeString}`;
}

/**
 * Transform "last, first" => "First Last" or just capitalizes words
 */
function transformToFirstLast(fullName) {
  if (!fullName) return '';
  const cleaned = fullName.trim();
  if (cleaned.includes(',')) {
    const [lastRaw, firstRaw] = cleaned.split(',').map((s) => s.trim());
    return `${capitalize(firstRaw)} ${capitalize(lastRaw)}`;
  } else {
    return cleaned
      .split(/\s+/)
      .map((s) => capitalize(s))
      .join(' ');
  }
}

/** Simple capitalize function */
function capitalize(str) {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

/**
 * Compute an "overall completion" from all store objectives
 */
function getOverallCompletion(store) {
  const objectives = store.overview?.objectives || [];
  if (objectives.length === 0) return 0;
  let sum = 0;
  for (const obj of objectives) {
    const numericVal = parseFloat(obj.percentage) || 0;
    sum += numericVal;
  }
  return sum / objectives.length; // average
}

/**
 * A small circle for historical 3/6/12 mo completion
 */
function SmallCircle({ percent }) {
  if (percent === 'NED') {
    return (
      <Box
        w="50px"
        h="50px"
        bg="#f0f0f0"
        borderRadius="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="#424242"
        fontWeight="bold"
        fontSize="0.8em"
      >
        -
      </Box>
    );
  }

  const numeric = parseFloat(percent) || 0;
  return (
    <Box w="50px" h="50px">
      <CircularProgressbar
        value={numeric}
        text={`${percent}`}
        styles={buildStyles({
          pathColor: '#1976d2',
          trailColor: '#dadada',
          textColor: '#555',
          textSize: '19px',
          strokeLinecap: 'round',
        })}
      />
    </Box>
  );
}

function TZv2Stores({ userGroup, company, username, userStore }) {
  const [stores, setStores] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // The heading text at the top
  const [monthYearTitle, setMonthYearTitle] = useState('');

  // For incompletes modal
  const [incompletesData, setIncompletesData] = useState([]);
  const [isIncompletesModalOpen, setIsIncompletesModalOpen] = useState(false);
  const [currentStoreId, setCurrentStoreId] = useState(null);

  // Check if day <= 6
  const today = new Date();
  const dayOfMonth = today.getDate();
  const isEarlyInMonth = dayOfMonth <= 6;

  // The toggle for viewing "Previous Month" or not
  const [viewingPreviousMonth, setViewingPreviousMonth] = useState(false);

	console.log("User Store: ", userStore);

  /**
   * We'll define a function to compute the heading text
   * based on whether we're in previousMonth mode or current month.
   */
  const computeMonthYearTitle = () => {
    if (!isEarlyInMonth) {
      // If it's not early in the month, just show the current month
      const now = new Date();
      const monthName = now.toLocaleString('default', { month: 'long' });
      return `Totzone Overview ${monthName} ${now.getFullYear()}`;
    } else {
      // If day <= 6, we check the toggle
      if (viewingPreviousMonth) {
        // Show last month's name
        const lastMonthDate = new Date(today.getFullYear(), today.getMonth(), 1);
        lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
        const monthName = lastMonthDate.toLocaleString('default', { month: 'long' });
        const year = lastMonthDate.getFullYear();
        return `Totzone Overview ${monthName} ${year}`;
      } else {
        // The user is toggled to "current month"
        const now = new Date();
        const monthName = now.toLocaleString('default', { month: 'long' });
        return `Totzone Overview ${monthName} ${now.getFullYear()}`;
      }
    }
  };

  /**
   * Build param (or logic) if we actually want to fetch different data for previous vs current month
   * We WILL pass this as "?month=YYYY-MM" to the backend.
   */
  const getFetchParams = () => {
    if (isEarlyInMonth && viewingPreviousMonth) {
      // We want the previous month's data
      const lastMonthDate = new Date(today.getFullYear(), today.getMonth(), 1);
      lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);

      // e.g. "2023-08"
      const fetchYear = lastMonthDate.getFullYear();
      const fetchMonth = String(lastMonthDate.getMonth() + 1).padStart(2, '0');
      return `${fetchYear}-${fetchMonth}`;
    } else {
      // Current month
      const fetchYear = today.getFullYear();
      const fetchMonth = String(today.getMonth() + 1).padStart(2, '0');
      return `${fetchYear}-${fetchMonth}`;
    }
  };

  /**
   * Decide the base URL depending on environment
   */
  const getBaseUrl = () => {
    const hostname = window.location.hostname;
    if (hostname === '72.167.34.236') {
      return 'http://72.167.34.236:5000';
    } else if (/^(www\.)?prolifi\.app$/.test(hostname)) {
      return 'https://prolifi.app';
    }
    return '';
  };

  /**
   * Fetch incomplete objectives => open modal
   */
  const handleFetchIncompletes = async (storeId) => {
    try {
      const baseUrl = getBaseUrl();
      const monthParam = getFetchParams();
      const url = `${baseUrl}/api/tzv2/store-incompletes?store_id=${storeId}&month=${monthParam}`;
      const res = await axios.get(url, { withCredentials: true });

      if (res.data.length === 0) {
        // NO employees to print
        alert('No employees to print!');
        return;
      }

      setIncompletesData(res.data);
      setIsIncompletesModalOpen(true);
      setCurrentStoreId(storeId);
    } catch (err) {
      console.error('Error fetching incompletes:', err);
      alert('Failed to retrieve incomplete objectives.');
    }
  };

  /**
   * Fetch store data (objectives, lastUpload, top employees, etc.)
   */
  const fetchAllStoresAndTopEmployees = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const baseUrl = getBaseUrl();
      let response;

      const monthParam = getFetchParams();

      if (userGroup === 'supervisor') {
        response = await axios.get(`${baseUrl}/api/tzv2/supervisor-stores?month=${monthParam}`, {
          withCredentials: true,
        });
      } else if (userGroup === 'director') {
        response = await axios.get(`${baseUrl}/api/tzv2/director-stores?month=${monthParam}`, {
          withCredentials: true,
        });
      } else {
        response = await axios.get(`${baseUrl}/api/tzv2/store?store_id=${userStore}&month=${monthParam}`, {
          withCredentials: true,
        });
      }

      let storeData = response.data;
      storeData = Array.isArray(storeData) ? storeData : [storeData];

      // For each store, also fetch top employees
      for (let s of storeData) {
        if (!s.store_id) continue;
        if (!s.overview) s.overview = {};

        const topRes = await axios.get(
          `${baseUrl}/api/tzv2/top-employees?store_id=${s.store_id}&month=${monthParam}`,
          { withCredentials: true }
        );
        s.overview.topEmployees = topRes.data;
      }

      // Sort
      storeData.sort((a, b) => {
        const aOverall = getOverallCompletion(a);
        const bOverall = getOverallCompletion(b);
        if (aOverall === bOverall) {
          return a.store_name.localeCompare(b.store_name);
        }
        return aOverall - bOverall; // ascending
      });

      setStores(storeData);
    } catch (err) {
      console.error('Error fetching stores or top employees:', err);
      setError('Failed to load store data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * On first mount, compute initial heading + fetch data
   */
  useEffect(() => {
    setMonthYearTitle(computeMonthYearTitle());
    fetchAllStoresAndTopEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroup, userStore]);

  /**
   * If the user toggles "Previous Month" <-> "Current Month",
   * we update the heading and re-fetch data
   */
  useEffect(() => {
    if (isEarlyInMonth) {
      const newTitle = computeMonthYearTitle();
      setMonthYearTitle(newTitle);

      // Re-fetch data
      fetchAllStoresAndTopEmployees();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewingPreviousMonth]);

  // If loading
  if (isLoading) {
    return (
      <Flex w="100%" minH="100vh" align="center" justify="center" bg="gray.50">
        <LoadingIndicator />
      </Flex>
    );
  }

  // If error
  if (error) {
    return (
      <Flex w="100%" minH="100vh" align="center" justify="center" bg="gray.50" p={4}>
        <Box bg="red.50" p={4} borderRadius="md">
          <Text color="red.700">{error}</Text>
        </Box>
      </Flex>
    );
  }

  // If no stores
  if (!stores || stores.length === 0) {
    return (
      <Flex w="100%" minH="100vh" align="center" justify="center" bg="gray.50" p={4}>
        <Text color="gray.500" fontStyle="italic">
          You have no stores assigned or no data available.
        </Text>
      </Flex>
    );
  }

  return (
    <Box w="100%" minH="100vh" bg="gray.50" px={8} py={8}>
      {/* Title + OPTIONAL toggle if within first 6 days of month */}
      <Box textAlign="center" mb={4}>
        <Heading
          as="h1"
          size="xl"
          fontWeight="extrabold"
          bgGradient="linear(to-r, teal.500, blue.500)"
          bgClip="text"
        >
          {monthYearTitle}
        </Heading>

        {isEarlyInMonth && (
          <FormControl
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            mt={3}
          >
            <FormLabel htmlFor="previousMonthToggle" mb="0" fontSize="sm" mr={2}>
              View Previous Month
            </FormLabel>
            <Switch
              id="previousMonthToggle"
              isChecked={viewingPreviousMonth}
              onChange={(e) => setViewingPreviousMonth(e.target.checked)}
              colorScheme="teal"
            />
          </FormControl>
        )}
      </Box>

      {/* Grid of store cards */}
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
        {stores.map((store) => (
          <Box
            key={store.store_id}
            bg="white"
            p={6}
            borderRadius="lg"
            boxShadow="md"
            display="flex"
            flexDirection="column"
            transition="box-shadow 0.2s ease"
            _hover={{
              boxShadow: '0 4px 12px rgba(0,0,0,0.12)',
            }}
          >
            <Heading as="h3" size="md" mb={1} color="gray.700" fontWeight="semibold">
              {store.store_name}
            </Heading>

            {store.overview?.lastUpload && (
              <Text fontSize="sm" color="gray.600" mb={2}>
                As of last data upload: {formatCentralTime(store.overview.lastUpload)}
              </Text>
            )}

            {store.overview && (
              <Box mt="auto">
                <Flex direction="row" align="flex-start" gap={4}>
                  {/* Left side: objectives + top employees */}
                  <Box flex="1">
                    {/* Circles + Print Button Row */}
                    <Flex direction="row" justify="space-between" align="flex-start">
                      {/* Big Objective Circles */}
                      <Flex wrap="wrap" gap={4}>
                        {store.overview.objectives?.map((obj) => {
                          const label = getObjectiveLabel(obj.objectiveName);
                          const numericVal = parseFloat(obj.percentage) || 0;

                          return (
                            <Flex
                              key={obj.objectiveName}
                              direction="column"
                              align="center"
                              textAlign="center"
                            >
                              <Text
                                fontSize="xs"
                                mb={1}
                                color="gray.600"
                                fontWeight="medium"
                              >
                                {label}
                              </Text>

                              <Box
                                className={styles.bigCircle}
                                w="100px"
                                h="100px"
                                position="relative"
                              >
                                <CircularProgressbar
                                  value={numericVal}
                                  text={obj.percentage}
                                  styles={buildStyles({
                                    pathColor: '#1976d2',
                                    trailColor: '#dadada',
                                    textColor: '#555',
                                    textSize: '15px',
                                    strokeLinecap: 'round',
                                  })}
                                />
                                <div className={styles.objTooltip}>
                                  {obj.completed} / {obj.total} Completed
                                </div>
                              </Box>
                            </Flex>
                          );
                        })}
                      </Flex>

                      {/* Print (incompletes) Icon Button */}
                      <IconButton
                        aria-label="fetch-incompletes"
                        icon={<FiPrinter />}
                        size="sm"
                        variant="ghost"
                        mt="-8px"
                        mr="-8px"
                        onClick={() => handleFetchIncompletes(store.store_id)}
                        _hover={{ bg: 'gray.100' }}
                      />
                    </Flex>

                    {/* Top 3 Employees */}
                    {store.overview.topEmployees &&
                      store.overview.topEmployees.length > 0 && (
                        <Box w="100%" mt={3}>
                          <Text
                            fontWeight="bold"
                            mb={2}
                            fontSize="sm"
                            color="gray.700"
                          >
                            Top 3 Employees
                          </Text>
                          {store.overview.topEmployees.map((emp, idx) => {
                            const name = transformToFirstLast(emp.userFullName);
                            return (
                              <Flex
                                key={emp.userFullName || idx}
                                justify="space-between"
                                align="center"
                                mb="4px"
                              >
                                <Text fontSize="sm" color="gray.700">
                                  {idx + 1}. {name}
                                </Text>
                                <Text fontSize="xs" color="gray.500">
                                  {emp.completionPct}%
                                </Text>
                              </Flex>
                            );
                          })}
                        </Box>
                      )}
                  </Box>

                  {/* Vertical divider */}
                  <Divider orientation="vertical" borderColor="gray.300" />

                  {/* Right side: historical 3/6/12 Mo */}
                  <Flex
                    direction="column"
                    align="center"
                    textAlign="center"
                    gap={2}
                    flex="0 0 auto"
                  >
                    <Text fontSize="sm" fontWeight="semibold" color="gray.700">
                      Historical Completion
                    </Text>

                    <Flex direction="column" align="center" gap={4}>
                      <Flex direction="column" align="center">
                        <Text fontSize="xs" color="gray.500" mb="2px">
                          3 Months
                        </Text>
                        <SmallCircle percent={store.overview.threeMonthPercent} />
                      </Flex>

                      <Flex direction="column" align="center">
                        <Text fontSize="xs" color="gray.500" mb="2px">
                          6 Months
                        </Text>
                        <SmallCircle percent={store.overview.sixMonthPercent} />
                      </Flex>

                      <Flex direction="column" align="center">
                        <Text fontSize="xs" color="gray.500" mb="2px">
                          12 Months
                        </Text>
                        <SmallCircle percent={store.overview.twelveMonthPercent} />
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Box>
            )}
          </Box>
        ))}
      </SimpleGrid>

      {/* The Incompletes Modal */}
      <IncompletesModal
        open={!!incompletesData && incompletesData.length > 0 && isIncompletesModalOpen}
        onClose={() => setIsIncompletesModalOpen(false)}
        incompletes={incompletesData}
        onRefresh={() => handleFetchIncompletes(currentStoreId)}
      />
    </Box>
  );
}

export default TZv2Stores;
