// generatePopsReportExcel.js
import ExcelJS from 'exceljs';
import { formatExcelReport } from './formatExcelReportv2';

export const captureTableAndDownloadExcel = async (reportData, selectedMonth, selectedArea, gradingStandards) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Pops Report');

  // Use the formatter to populate the worksheet.
  formatExcelReport(worksheet, reportData, selectedMonth, selectedArea, gradingStandards);

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'Pops_Report.xlsx';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
