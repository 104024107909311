// File: src/components/ProlifiField/Schedules/director/DirectorCustomModal.jsx
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import axios from 'axios';
import { getBaseUrl } from '../../../../utils/getBaseUrl';

/**
 * Convert "YYYY-MM-DD" and "HH:mm" into "YYYY-MM-DD HH:mm:ss" for DB.
 */
function combineDateAndTime(dateString, timeString) {
  if (!dateString) return '';
  // If time is empty or invalid, fallback to "00:00:00"
  const safeTime = timeString || '00:00';
  return `${dateString} ${safeTime}:00`;
}

/**
 * For the <input type="time"> we use "HH:mm".
 * We will enforce a min of "03:00" and max "23:59".
 */

export default function DirectorCustomModal({
  isOpen,
  eventData,
  onClose,
  onSave,
  onDelete,
  directorStores = []
}) {
  // 1) The user’s supervised store list from /my_sup_stores
  const [myStoreList, setMyStoreList] = useState([]);

  // 2) Some static items
  const staticItems = [
    { id: 'Office',   text: 'Office' },
    { id: 'Meetings', text: 'Meetings' },
    { id: 'Vacation', text: 'Vacation' }
  ];
  const [combinedOptions, setCombinedOptions] = useState([]);

  // 3) Our local form data
  const [formData, setFormData] = useState({
    Id: null,
    SupervisorId: null,
    Subject: '',
    EventDate: '',      // single date
    StartTime: '09:00', // time only (HH:mm)
    EndTime: '17:00',   // time only (HH:mm)
    Description: '',
    CombinedId: '-- Select an option --',
    Reasons: [],
    ReasonOther: ''
  });

  // 4) If modal is opening => fetch user’s store list
  useEffect(() => {
    if (isOpen) {
      fetchMySupervisorStores();
    }
  }, [isOpen]);

  async function fetchMySupervisorStores() {
    try {
      const baseUrl = getBaseUrl();
      const url = `${baseUrl}/pfield_events/pfield_director/my_sup_stores`;
      const res = await axios.get(url, { withCredentials: true });
      if (res.data.success && Array.isArray(res.data.stores)) {
        const storeOpts = res.data.stores.map(s => ({
          id: String(s.store_id),
          text: s.store_name
        }));
        setMyStoreList(storeOpts);
      } else {
        setMyStoreList([]);
      }
    } catch (err) {
      console.error('Error fetching my sup stores =>', err);
      setMyStoreList([]);
    }
  }

  // 5) Merge store list + static items
  useEffect(() => {
    setCombinedOptions([...myStoreList, ...staticItems]);
  }, [myStoreList]);

  // 6) On eventData changes => parse existing or set defaults
  useEffect(() => {
    if (!eventData) {
      // NEW SHIFT => default day = today, times 9–5
      const today = new Date();
      const y = today.getFullYear(), m = today.getMonth(), d = today.getDate();
      const monthStr = String(m+1).padStart(2, '0');
      const dayStr   = String(d).padStart(2, '0');
      const dateStr = `${y}-${monthStr}-${dayStr}`;

      setFormData({
        Id: null,
        SupervisorId: null,
        Subject: '',
        EventDate: dateStr,
        StartTime: '09:00',
        EndTime: '17:00',
        Description: '',
        CombinedId: '-- Select an option --',
        Reasons: [],
        ReasonOther: ''
      });
      return;
    }

    // EDIT SHIFT => parse Subject, combinedId, reasons
    const oldSubject = eventData.Subject || '';
    let parsedReasons = [];
    let combinedId = '-- Select an option --';

    // We'll try to see if there's a dash for reasons
    if (oldSubject.includes(' - ')) {
      const idx = oldSubject.indexOf(' - ');
      const afterDash = oldSubject.substring(idx + 3).trim();
      parsedReasons = afterDash.split(',').map(r => r.trim()).filter(Boolean);

      const storeName = oldSubject.substring(0, idx).trim();
      const foundOpt = combinedOptions.find(o => o.text === storeName);
      if (foundOpt) {
        combinedId = foundOpt.id;
      }
    } else {
      // Maybe it's just "Vacation" or "Office", no dash
      const foundOpt = combinedOptions.find(o => o.text === oldSubject);
      if (foundOpt) {
        combinedId = foundOpt.id;
      }
    }

    // Now parse the existing startTime/endTime => "YYYY-MM-DD" plus "HH:mm"
    const s = new Date(eventData.startTime);
    const e = new Date(eventData.endTime);

    function dateStr(dt) {
      const yyyy = dt.getFullYear();
      const mm   = String(dt.getMonth() + 1).padStart(2, '0');
      const dd   = String(dt.getDate()).padStart(2, '0');
      return `${yyyy}-${mm}-${dd}`;
    }
    function timeStr(dt) {
      const hh = String(dt.getHours()).padStart(2, '0');
      const mn = String(dt.getMinutes()).padStart(2, '0');
      return `${hh}:${mn}`;
    }

    setFormData({
      Id: eventData.Id ?? null,
      SupervisorId: eventData.SupervisorId ?? null,
      Subject: oldSubject,
      EventDate: !isNaN(s) ? dateStr(s) : '',
      StartTime: !isNaN(s) ? timeStr(s) : '09:00',
      EndTime:   !isNaN(e) ? timeStr(e) : '17:00',
      Description: eventData.Description ?? '',
      CombinedId: combinedId,
      Reasons: parsedReasons,
      ReasonOther: ''
    });

  // eslint-disable-next-line
  }, [eventData, combinedOptions]);

  // 7) Build reason options
  function getReasonOptionsForCombinedId(combinedIdVal) {
    if (!combinedIdVal || combinedIdVal === '-- Select an option --') return [];
    const foundOpt = combinedOptions.find(o => o.id === combinedIdVal);
    if (!foundOpt) return [];

    const textVal = foundOpt.text;
    if (textVal === 'Vacation') {
      return [];
    }
    if (textVal === 'Office') {
      return ['End of Week', 'Schedule Review', 'Inventory Review', 'End of Month', 'Other'];
    }
    if (textVal === 'Meetings') {
      return ['GM/Area', 'Supervisor', 'Class: AMLC', 'Class: EGMC', 'Other'];
    }
    // Otherwise assume it's a store => typical store reasons
    return [
      'General Visit',
      'Struggling Sales, Profit, or KPIs',
      'Audit/Quarterly',
      'Working w/ MIT',
      'GM not Present',
      'Other',
    ];
  }

  const reasonStrings = getReasonOptionsForCombinedId(formData.CombinedId);
  const reasonOptions = reasonStrings.map(r => ({ value: r, label: r }));
  const isOtherSelected = formData.Reasons.includes('Other');

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData(prev => {
      const updated = { ...prev, [name]: value };

      // If user changes the store/event, ensure we re-add "General Visit" for store
      if (name === 'CombinedId') {
        updated.Reasons = [];
        updated.ReasonOther = '';
      }

      return updated;
    });
  }

  function handleReasonsChange(selected) {
    if (!selected) {
      setFormData(prev => ({ ...prev, Reasons: [] }));
      return;
    }
    const selectedReasons = selected.map(x => x.value);
    setFormData(prev => ({ ...prev, Reasons: selectedReasons }));
  }

  // 8) handle Save => ensures no multi-day, time >= 3:00, time <= 23:59, end >= start, etc.
  async function handleSave() {
    if (formData.CombinedId === '-- Select an option --') {
      alert('Please pick a store/event first.');
      return;
    }

    // If there are reason options, we require at least one
    if (reasonStrings.length > 0 && formData.Reasons.length === 0) {
      alert('Please pick at least one reason.');
      return;
    }

    // If "Other" chosen, replace it with user’s typed text
    let finalReasons = [...formData.Reasons];
    if (isOtherSelected && formData.ReasonOther.trim()) {
      const idx = finalReasons.indexOf('Other');
      if (idx !== -1) {
        finalReasons[idx] = formData.ReasonOther.trim();
      }
    }

    // Validate times
    // We expect StartTime and EndTime in HH:mm format, enforce min=03:00
    if (!formData.StartTime || !formData.EndTime) {
      alert('Please pick both a start time and an end time.');
      return;
    }

    // Compare start/end as minutes from midnight
    function toMinutes(t) {
      const [hh, mm] = t.split(':').map(v => parseInt(v, 10));
      return hh * 60 + mm;
    }
    const startMin = toMinutes(formData.StartTime);
    const endMin   = toMinutes(formData.EndTime);

    if (startMin < 3 * 60) {
      alert('Start time cannot be before 03:00.');
      return;
    }
    if (endMin > 23 * 60 + 59) {
      alert('End time cannot be after 23:59.');
      return;
    }
    if (endMin <= startMin) {
      alert('End time must be after start time, on the same day.');
      return;
    }

    // Build the final Subject => e.g., "Office - Schedule Review, Inventory Review"
    const foundOpt = combinedOptions.find(o => o.id === formData.CombinedId);
    const storeName = foundOpt ? foundOpt.text : 'Unknown';
    const reasonsStr = finalReasons.join(', ');
    let newSubject = storeName;
    if (reasonsStr) {
      newSubject += ` - ${reasonsStr}`;
    }

    // Combine date + times for DB
    const startStr = combineDateAndTime(formData.EventDate, formData.StartTime);
    const endStr   = combineDateAndTime(formData.EventDate, formData.EndTime);

    const payload = {
      Subject: newSubject,
      StartTime: startStr,
      EndTime: endStr,
      Description: formData.Description || '',
      CombinedId: formData.CombinedId,
      // Arbitrary category
      CategoryId: 1
    };

    try {
      const baseUrl = getBaseUrl();
      if (formData.Id) {
        // Updating existing
        await axios.put(`${baseUrl}/pfield_events/${formData.Id}`, payload, { withCredentials: true });
        console.log('[DirectorCustomModal] => updated shift =>', formData.Id);
      } else {
        // Creating new
        const res = await axios.post(`${baseUrl}/pfield_events`, payload, { withCredentials: true });
        console.log('[DirectorCustomModal] => new shift inserted =>', res.data);
        if (res.data.insertedId) {
          formData.Id = res.data.insertedId;
        }
      }

      // Let parent update local events
      onSave({
        ...formData,
        Subject: newSubject,
        // SupervisorId so the parent can show it immediately
        SupervisorId: formData.SupervisorId,
        startTime: startStr,
        endTime: endStr
      });

    } catch (err) {
      console.error('[DirectorCustomModal] => handleSave => error =>', err);
      alert('Error saving shift to the database. Check console.');
    }
  }

  function handleDeleteClick() {
    if (formData.Id != null) {
      onDelete(formData.Id);
    }
  }

  // 9) Modal styles
  const customStyles = {
    content: {
      maxWidth: '700px',
      margin: 'auto',
      borderRadius: '10px',
      padding: '20px',
      border: '1px solid #ddd',
      boxShadow: '0 4px 16px rgba(0,0,0,0.2)',
      height: 'auto',
      maxHeight: '75vh',
      overflow: 'auto'
    },
    overlay: {
      zIndex: 999999,
      backgroundColor: 'rgba(0,0,0,0.5)'
    }
  };

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Director Editor Modal"
      ariaHideApp={false}
      style={customStyles}
    >
      <h2 style={{ marginTop: 0, marginBottom: '1rem' }}>
        {formData.Id ? 'Edit Director Event' : 'Create Director Event'}
      </h2>

      {/* Store/Event dropdown */}
      <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
        <div style={{ flex: 1 }}>
          <label style={{ fontWeight: 'bold', display: 'block' }}>Store/Event</label>
          <select
            name="CombinedId"
            value={formData.CombinedId}
            onChange={handleChange}
            style={{
              width: '100%',
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #ccc'
            }}
          >
            <option value="-- Select an option --">-- Select an option --</option>
            {combinedOptions.map(opt => (
              <option key={opt.id} value={opt.id}>
                {opt.text}
              </option>
            ))}
          </select>
        </div>

        {/* If reasonStrings => show a multi-select for reasons */}
        {reasonStrings.length > 0 && (
          <div style={{ flex: 1 }}>
            <label style={{ fontWeight: 'bold', display: 'block' }}>Reasons</label>
            <Select
              isMulti
              options={reasonOptions}
              value={reasonOptions.filter(opt => formData.Reasons.includes(opt.value))}
              onChange={handleReasonsChange}
              placeholder="Select reason(s)..."
            />
          </div>
        )}
      </div>

      {/* If "Other" => show the ReasonOther input */}
      {isOtherSelected && (
        <div style={{ marginBottom: '1rem' }}>
          <label style={{ fontWeight: 'bold', display: 'block' }}>Other Reason</label>
          <input
            type="text"
            name="ReasonOther"
            value={formData.ReasonOther}
            onChange={handleChange}
            placeholder="Type your custom reason..."
            style={{
              width: '100%',
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #ccc'
            }}
          />
        </div>
      )}

      {/* Single date field + start/end times */}
      <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
        <div style={{ flex: 1 }}>
          <label style={{ fontWeight: 'bold', display: 'block' }}>Date</label>
          <input
            type="date"
            name="EventDate"
            value={formData.EventDate}
            onChange={handleChange}
            style={{
              width: '100%',
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #ccc'
            }}
          />
        </div>
        <div style={{ flex: 1 }}>
          <label style={{ fontWeight: 'bold', display: 'block' }}>Start Time</label>
          <input
            type="time"
            name="StartTime"
            min="03:00"
            max="23:59"
            value={formData.StartTime}
            onChange={handleChange}
            style={{
              width: '100%',
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #ccc'
            }}
          />
        </div>
        <div style={{ flex: 1 }}>
          <label style={{ fontWeight: 'bold', display: 'block' }}>End Time</label>
          <input
            type="time"
            name="EndTime"
            min="03:00"
            max="23:59"
            value={formData.EndTime}
            onChange={handleChange}
            style={{
              width: '100%',
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #ccc'
            }}
          />
        </div>
      </div>

      {/* Description (optional) */}
      <div style={{ marginBottom: '1rem' }}>
        <label style={{ fontWeight: 'bold', display: 'block' }}>Description</label>
        <textarea
          name="Description"
          rows={3}
          style={{
            width: '100%',
            padding: '8px',
            borderRadius: '4px',
            border: '1px solid #ccc'
          }}
          placeholder="Notes or details..."
          value={formData.Description}
          onChange={handleChange}
        />
      </div>

      {/* Buttons */}
      <div style={{ marginTop: '1.5rem', display: 'flex', gap: '1rem' }}>
        {formData.Id && (
          <button
            onClick={handleDeleteClick}
            style={{
              background: '#f44336',
              color: '#fff',
              padding: '8px 16px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            Delete
          </button>
        )}
        <button
          onClick={handleSave}
          style={{
            background: '#2196f3',
            color: '#fff',
            padding: '8px 16px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Save
        </button>
        <button
          onClick={onClose}
          style={{
            background: '#bbb',
            color: '#333',
            padding: '8px 16px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
}
