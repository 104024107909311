// File: src/components/ProlifiField/Schedules/wsn/super/WeeklyStoreNotesModal.jsx

import React, { useState, useEffect } from 'react';
import { 
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
  ModalCloseButton, 
  Button, 
  Text, 
  IconButton, 
  Flex, 
  Spinner, 
  VStack, 
  Box, 
  Heading,
  Textarea,
  HStack,
  Input,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useColorModeValue
} from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon, DeleteIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { getBaseUrl } from '../../../../utils/getBaseUrl';

// Default notes structure if we can't find anything else.
// (Used only if no notes exist for the store in notesData.)
const defaultNotes = {
  people: "",
  operations: "",
  profit: {
    foodVariance: "",
    crewProduction: "",
    cashPlusMinus: "",
    other: ""
  },
  sales: "",
  mitCards: [], // example structure
  followUpTasks: ""
};

export default function WeeklyStoreNotesModal({ isOpen, onClose, stores }) {
  // State for store navigation
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  
  // State for dynamic categories (loaded from DB)
  const [categories, setCategories] = useState([]);
  
  // notesData => { storeId: { [cat.id]: <varies> }, ... }
  const [notesData, setNotesData] = useState({});

  // On open, load data if needed
  useEffect(() => {
    if (isOpen) {
      loadCategories();
      setCurrentIndex(0);
      // We do NOT re-fetch the stores inside the modal if we already have them from props.
      // If you prefer re-fetching stores, call loadStores() here. 
    }
  }, [isOpen]);

  async function loadCategories() {
    try {
      const url = `${getBaseUrl()}/pfield_wsn/categories`;
      const res = await axios.get(url, { withCredentials: true });
      setCategories(res.data || []);
    } catch (err) {
      console.error('Error fetching categories =>', err);
    }
  }

  // The current store
  const isEmpty = !stores || stores.length === 0;
  const currentStore = isEmpty ? null : stores[currentIndex];
  const storeId = currentStore ? currentStore.store_id : null;

  // Initialize store's notes in notesData if missing
  useEffect(() => {
    if (storeId && categories.length > 0) {
      setNotesData((prev) => {
        // If notes for this store do not exist yet, create them
        if (!prev[storeId]) {
          const initialNotes = {};
          categories.forEach((cat) => {
            if (cat.type === "textarea") {
              // subQuestions => array of textareas
              if (cat.subQuestions && cat.subQuestions.length > 0) {
                // initialize as array of empty strings
                initialNotes[cat.id] = cat.subQuestions.map(() => "");
              } else {
                // single empty string
                initialNotes[cat.id] = "";
              }
            } else if (cat.type === "mit") {
              // initialize MIT as an empty array
              initialNotes[cat.id] = [];
            } else {
              // fallback
              initialNotes[cat.id] = "";
            }
          });
          return { ...prev, [storeId]: initialNotes };
        }
        return prev;
      });
    }
  }, [storeId, categories]);

  // currentNotes => for the current store, or fallback to defaultNotes
  const currentNotes = storeId && notesData[storeId] 
    ? notesData[storeId] 
    : defaultNotes;

  // Helper to update a single note
  function updateNote(categoryId, value) {
    if (!storeId) return;
    setNotesData((prev) => ({
      ...prev,
      [storeId]: {
        ...prev[storeId],
        [categoryId]: value
      }
    }));
  }

  // Helper to update sub-questions array
  function updateSubNote(categoryId, subIndex, value) {
    if (!storeId) return;
    setNotesData((prev) => {
      const currentCategoryArray = prev[storeId][categoryId] || [];
      const updated = [...currentCategoryArray];
      updated[subIndex] = value;
      return {
        ...prev,
        [storeId]: {
          ...prev[storeId],
          [categoryId]: updated
        }
      };
    });
  }

  // Navigation
  function goPrev() {
    setCurrentIndex((idx) => (idx > 0 ? idx - 1 : idx));
  }
  function goNext() {
    setCurrentIndex((idx) => (idx < stores.length - 1 ? idx + 1 : idx));
  }

  // MIT card handlers
  function handleAddMitCard() {
    const mitCategory = categories.find((cat) => cat.type === "mit");
    if (!mitCategory) return;
    const newMitCards = [ ...(currentNotes[mitCategory.id] || []) ];
    newMitCards.push({
      name: "",
      rating: 5,
      timeSpent: "",
      accomplishments: "",
      trainingStatus: ""
    });
    updateNote(mitCategory.id, newMitCards);
  }

  function handleRemoveMitCard(index) {
    const mitCategory = categories.find((cat) => cat.type === "mit");
    if (!mitCategory) return;
    const existing = currentNotes[mitCategory.id] || [];
    const newMitCards = existing.filter((_, i) => i !== index);
    updateNote(mitCategory.id, newMitCards);
  }

  // Chakra color modes
  const modalBg     = useColorModeValue("white", "gray.800");
  const headerBg    = useColorModeValue("blue.50",  "blue.900");
  const storeTextColor = useColorModeValue("blue.700", "blue.200");
  const categoryBg  = useColorModeValue("gray.50", "gray.700");
  // We define the MIT card background color outside the map:
  const mitCardBg   = useColorModeValue("white", "gray.800");

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose} 
      size="3xl" 
      isCentered 
      scrollBehavior="inside"
    >
      <ModalOverlay bg="blackAlpha.600" />
      <ModalContent 
        bg={modalBg} 
        borderRadius="12px" 
        boxShadow="2xl" 
        overflow="hidden"
        w={{ base: "100vw", md: "80vw" }}
        maxH={{ base: "100vh", md: "90vh" }}
      >
        <ModalHeader 
          bg={headerBg} 
          fontSize="xl" 
          fontWeight="bold" 
          borderBottom="1px solid" 
          borderColor="gray.200"
        >
          Weekly Store Notes
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto" py={4}>
          {loading ? (
            <Flex align="center" justify="center" height="100px">
              <Spinner size="lg" />
            </Flex>
          ) : isEmpty ? (
            <Text>No stores found, or not authorized.</Text>
          ) : (
            <>
              {/* Store Navigation */}
              <Flex align="center" justify="center" mb={6} h="40px">
                <IconButton
                  icon={<ArrowLeftIcon />}
                  aria-label="Previous store"
                  variant="ghost"
                  isDisabled={currentIndex === 0}
                  onClick={goPrev}
                  mr={2}
                />
                <Text 
                  fontSize="md" 
                  fontWeight="medium" 
                  color={storeTextColor}
                  lineHeight="40px"
                >
                  {currentStore
                    ? `#${currentStore.store_id} – ${currentStore.store_name || 'Unknown'}`
                    : ''}
                </Text>
                <IconButton
                  icon={<ArrowRightIcon />}
                  aria-label="Next store"
                  variant="ghost"
                  isDisabled={currentIndex === stores.length - 1}
                  onClick={goNext}
                  ml={2}
                />
              </Flex>

              {/* Render categories */}
              <VStack spacing={8} align="stretch">
                {categories.map((cat) => (
                  <Box 
                    key={cat.id} 
                    p={4} 
                    bg={categoryBg} 
                    borderWidth="1px" 
                    borderRadius="md" 
                    boxShadow="sm"
                  >
                    <Heading size="md" mb={2}>{cat.categoryName}</Heading>
                    <Text mb={2}>{cat.question}</Text>

                    {/* TEXTAREA TYPE */}
                    {cat.type === "textarea" && (
                      <>
                        {cat.subQuestions && cat.subQuestions.length > 0 ? (
                          cat.subQuestions.map((sq, idx) => (
                            <Box key={idx} mt={2}>
                              <Text fontWeight="semibold">{sq.label}</Text>
                              <Textarea
                                placeholder={sq.placeholder}
                                size="sm"
                                value={
                                  Array.isArray(currentNotes[cat.id])
                                    ? currentNotes[cat.id][idx] || ""
                                    : ""
                                }
                                onChange={(e) => updateSubNote(cat.id, idx, e.target.value)}
                              />
                            </Box>
                          ))
                        ) : (
                          <Textarea
                            placeholder={cat.placeholder || ""}
                            size="sm"
                            mb={2}
                            value={currentNotes[cat.id] || ""}
                            onChange={(e) => updateNote(cat.id, e.target.value)}
                          />
                        )}
                      </>
                    )}

                    {/* MIT TYPE */}
                    {cat.type === "mit" && (
                      <>
                        <Button
                          size="sm"
                          variant="outline"
                          colorScheme="blue"
                          onClick={handleAddMitCard}
                          mb={4}
                        >
                          Add MIT Card
                        </Button>
                        {(currentNotes[cat.id] || []).length === 0 ? (
                          <Text fontSize="sm" color="gray.600">
                            No MIT cards created yet.
                          </Text>
                        ) : (
                          <VStack spacing={4} align="stretch">
                            {(currentNotes[cat.id] || []).map((card, index) => (
                              <Box
                                key={index}
                                p={4}
                                borderWidth="1px"
                                borderRadius="md"
                                boxShadow="md"
                                bg={mitCardBg}
                              >
                                <HStack justify="space-between" mb={2}>
                                  <Heading size="sm">MIT Card #{index + 1}</Heading>
                                  {(currentNotes[cat.id] || []).length > 1 && (
                                    <IconButton
                                      icon={<DeleteIcon />}
                                      aria-label="Remove MIT Card"
                                      size="xs"
                                      variant="outline"
                                      colorScheme="red"
                                      onClick={() => handleRemoveMitCard(index)}
                                    />
                                  )}
                                </HStack>

                                {/* Name */}
                                <Box mt={2}>
                                  <Text fontWeight="semibold" mb={1}>MIT Name:</Text>
                                  <Input
                                    placeholder="Enter MIT's name"
                                    value={card.name}
                                    onChange={(e) => {
                                      const newMitCards = [ ...(currentNotes[cat.id] || []) ];
                                      newMitCards[index].name = e.target.value;
                                      updateNote(cat.id, newMitCards);
                                    }}
                                    size="sm"
                                  />
                                </Box>

                                {/* Rating */}
                                <Box mt={2}>
                                  <Text fontWeight="semibold" mb={1}>Rating (1-10):</Text>
                                  <Slider
                                    value={card.rating}
                                    min={1}
                                    max={10}
                                    step={1}
                                    onChange={(val) => {
                                      const newMitCards = [ ...(currentNotes[cat.id] || []) ];
                                      newMitCards[index].rating = val;
                                      updateNote(cat.id, newMitCards);
                                    }}
                                  >
                                    <SliderTrack>
                                      <SliderFilledTrack />
                                    </SliderTrack>
                                    <SliderThumb boxSize={6} />
                                  </Slider>
                                  <Text fontSize="sm" mt={1}>
                                    Rating: {card.rating}
                                  </Text>
                                </Box>

                                {/* Time Spent */}
                                <Box mt={2}>
                                  <Text fontWeight="semibold" mb={1}>Time Spent with MIT:</Text>
                                  <Textarea
                                    placeholder="When did you spend time with this MIT?"
                                    value={card.timeSpent}
                                    onChange={(e) => {
                                      const newMitCards = [ ...(currentNotes[cat.id] || []) ];
                                      newMitCards[index].timeSpent = e.target.value;
                                      updateNote(cat.id, newMitCards);
                                    }}
                                    size="sm"
                                  />
                                </Box>

                                {/* Accomplishments */}
                                <Box mt={2}>
                                  <Text fontWeight="semibold" mb={1}>Accomplishments:</Text>
                                  <Textarea
                                    placeholder="What was accomplished?"
                                    value={card.accomplishments}
                                    onChange={(e) => {
                                      const newMitCards = [ ...(currentNotes[cat.id] || []) ];
                                      newMitCards[index].accomplishments = e.target.value;
                                      updateNote(cat.id, newMitCards);
                                    }}
                                    size="sm"
                                  />
                                </Box>

                                {/* Training Status */}
                                <Box mt={2}>
                                  <Text fontWeight="semibold" mb={1}>Training Status:</Text>
                                  <Textarea
                                    placeholder="Where is the MIT on their training?"
                                    value={card.trainingStatus}
                                    onChange={(e) => {
                                      const newMitCards = [ ...(currentNotes[cat.id] || []) ];
                                      newMitCards[index].trainingStatus = e.target.value;
                                      updateNote(cat.id, newMitCards);
                                    }}
                                    size="sm"
                                  />
                                </Box>

                                {/* Attach Photo button (optional) */}
                                <Button size="sm" mt={2} variant="outline" colorScheme="blue">
                                  Attach Photo
                                </Button>
                              </Box>
                            ))}
                          </VStack>
                        )}
                      </>
                    )}

                    {/* Photo Attachment Button (shown only if === 1 and not MIT) */}
                    {cat.photoAttachmentOption === 1 && cat.type !== "mit" && (
                      <Button size="sm" variant="outline" colorScheme="blue" mt={3}>
                        Attach Photo
                      </Button>
                    )}
                  </Box>
                ))}
              </VStack>
            </>
          )}
        </ModalBody>
        <ModalFooter borderTop="1px solid" borderColor="gray.200">
          <Button onClick={onClose} colorScheme="blue">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
