// File: src/utils/directorShiftValidation.js

/**
 * File: src/utils/directorShiftValidation.js
 *
 * validateShift handles:
 *   1) Checking if the user is dragging onto a parent row.
 *   2) Ensuring that the event's start time is before its end time.
 *
 * Returns an object: { cancel: boolean, message: string }
 *
 * Usage within DirectorScheduleSection:
 *   const { cancel, message } = validateShift(args, events, scheduleRef);
 *   if (cancel) {
 *     args.cancel = true;
 *     if (message) alert(message);
 *     return;
 *   }
 */

// Define the director resource constant
const DIRECTOR_RESOURCE_ID = -999;

export function validateShift(args, events, scheduleRef) {
  console.log('validateShift => invoked with args:', args);

  const response = {
    cancel: false,
    message: '',
  };

  // 1) Check if user is dragging onto the parent row
  if (args.requestType === 'eventChange' && scheduleRef.current) {
    console.log("validateShift => eventChange => check if parent row");
    let changedRecord = Array.isArray(args.data) ? args.data[0] : args.data;
    if (changedRecord?.Guid) {
      const el = scheduleRef.current.element.querySelector(
        `[data-guid="${changedRecord.Guid}"]`
      );
      if (el) {
        const domGroupIndex = el.getAttribute('data-group-index');
        console.log('validateShift => domGroupIndex = ', domGroupIndex);

        if (domGroupIndex != null) {
          const numericIndex = parseInt(domGroupIndex, 10);
          const resourceHierarchy =
            scheduleRef.current.getResourcesByIndex(numericIndex);
          console.log('resourceHierarchy => ', resourceHierarchy);

          if (resourceHierarchy?.length === 1) {
            response.cancel = true;
            response.message = 'Cannot drop onto the parent row!';
            return response;
          }
        }
      }
    }
  }

  // 2) Check that the event's start time is before its end time
  if (
    args.requestType === 'eventChange' ||
    args.requestType === 'eventCreate'
  ) {
    let newEvent = null;
    if (args.changedRecords?.length > 0) {
      newEvent = args.changedRecords[0];
    } else if (Array.isArray(args.data)) {
      newEvent = args.data[0];
    } else {
      newEvent = args.data;
    }

    if (!newEvent) return response;

    const startProp = newEvent.startTime || newEvent.StartTime;
    const endProp   = newEvent.endTime   || newEvent.EndTime;
    const newStart  = new Date(startProp);
    const newEnd    = new Date(endProp);

    if (newStart >= newEnd) {
      response.cancel = true;
      response.message = 'End time must be after start time.';
      return response;
    }
  }

  return response;
}
