// PopsReportTable.js
import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, Box } from "@chakra-ui/react";

function PopsReportTable({ data }) {
  const dataCellStyle = {
    padding: "6px",
    border: "1px solid #e2e8f0",
    fontSize: "sm",
    textAlign: "center",
  };

  const headerCellStyle = {
    padding: "6px",
    fontWeight: "bold",
    fontSize: "sm",
    backgroundColor: "#f0f0f0",
    border: "1px solid #e2e8f0",
    textAlign: "center",
  };

  if (!data || data.length === 0) {
    return (
      <Box p={4}>
        <strong>No data available for this selection.</strong>
      </Box>
    );
  }

  // Helper functions for formatting

  // Formats a number as currency, e.g., "$75,153.47"
  const formatCurrency = (value) => {
    const num = Number(value);
    if (isNaN(num)) return value;
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(num);
  };

  // Formats a number as a percentage with two decimals, e.g., "3.89%"
  const formatPercentage = (value) => {
    const num = Number(value);
    if (isNaN(num)) return value;
    return `${num.toFixed(2)}%`;
  };

  // Converts minutes (as a number) to "hours:minutes" format
  const formatTime = (minutes) => {
    const num = Number(minutes);
    if (isNaN(num)) return minutes;
    const h = Math.floor(num / 60);
    const m = Math.round(num % 60);
    return `${h}:${m < 10 ? "0" : ""}${m}`;
  };

  // Rounds a number to two decimals (for Complaints/10k)
  const formatNumber = (value) => {
    const num = Number(value);
    if (isNaN(num)) return value;
    return num.toFixed(2);
  };

  // Helper to safely get the background color from the store's KPI details.
  const getKpiColor = (store, kpiKey) => {
    return store.kpiDetails &&
      store.kpiDetails[kpiKey] &&
      store.kpiDetails[kpiKey].grade &&
      store.kpiDetails[kpiKey].grade.color
      ? store.kpiDetails[kpiKey].grade.color
      : "inherit";
  };

  return (
    <Box overflowX="auto">
      <TableContainer>
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th style={headerCellStyle}>Rank</Th>
              <Th style={{ ...headerCellStyle, textAlign: "right" }}>Store Name</Th>
              <Th style={headerCellStyle}>MTD Sales</Th>
              <Th style={headerCellStyle}>Sales % Change</Th>
              <Th style={headerCellStyle}>Cont. Expenses</Th>
              <Th style={headerCellStyle}>Crew Production</Th>
              <Th style={headerCellStyle}>Labor %</Th>
              <Th style={headerCellStyle}>Food Variance</Th>
              <Th style={headerCellStyle}>On Times %</Th>
              <Th style={headerCellStyle}>Average Time</Th>
              <Th style={headerCellStyle}>Reply Time</Th>
              <Th style={headerCellStyle}>Complaints/10k</Th>
              <Th style={headerCellStyle}>Cash</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row, idx) => (
              <Tr key={row.storeId || idx}>
                <Td style={dataCellStyle}>{row.overallRank}</Td>
                <Td style={{ ...dataCellStyle, textAlign: "right" }}>{row.storeName}</Td>
                <Td style={dataCellStyle}>{formatCurrency(row.mtdSales)}</Td>
                <Td
                  style={{
                    ...dataCellStyle,
                    backgroundColor: getKpiColor(row, "salesPctChange"),
                  }}
                >
                  {formatPercentage(row.salesPctChange)}
                </Td>
                <Td
                  style={{
                    ...dataCellStyle,
                    backgroundColor: getKpiColor(row, "contExpenses"),
                  }}
                >
                  {formatCurrency(row.contExpenses)}
                </Td>
                <Td
                  style={{
                    ...dataCellStyle,
                    backgroundColor: getKpiColor(row, "crewProduction"),
                  }}
                >
                  {formatCurrency(row.crewProduction)}
                </Td>
                <Td
                  style={{
                    ...dataCellStyle,
                    backgroundColor: getKpiColor(row, "laborPct"),
                  }}
                >
                  {formatPercentage(row.laborPct)}
                </Td>
                <Td
                  style={{
                    ...dataCellStyle,
                    backgroundColor: getKpiColor(row, "foodVariance"),
                  }}
                >
                  {row.foodVariance}
                </Td>
                <Td
                  style={{
                    ...dataCellStyle,
                    backgroundColor: getKpiColor(row, "onTimesPct"),
                  }}
                >
                  {formatPercentage(row.onTimesPct)}
                </Td>
                <Td
                  style={{
                    ...dataCellStyle,
                    backgroundColor: getKpiColor(row, "avgTime"),
                  }}
                >
                  {formatTime(row.avgTime)}
                </Td>
                <Td
                  style={{
                    ...dataCellStyle,
                    backgroundColor: getKpiColor(row, "replyTime"),
                  }}
                >
                  {formatTime(row.replyTime)}
                </Td>
                <Td
                  style={{
                    ...dataCellStyle,
                    backgroundColor: getKpiColor(row, "complaintsPer10K"),
                  }}
                >
                  {formatNumber(row.complaintsPer10K)}
                </Td>
                <Td
                  style={{
                    ...dataCellStyle,
                    backgroundColor: getKpiColor(row, "cash"),
                  }}
                >
                  {formatCurrency(row.cash)}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default PopsReportTable;
