// src/components/NavBar.js

import React, { useState, useRef } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  Box,
  Text,
  Button,
  Image,
  Flex,
} from "@chakra-ui/react";
import { storeOperationsUsers } from "./accessControl"; // your existing import
import ptechlogo from "./images/ptechlogo.png";

// Dictionary of path -> page title
const routeTitles = {
  "/prFileUpload": "Payroll Converter",
  "/pdfReview": "Post-Payroll Reports",
  "/csv-converter": "IX to PFC Truck Converter",
  "/bbUpload": "BlueBook Upload",
  "/pops-report": "Pops Report",
  "/w-manual": "Weekly Manual Data",
  "/monthly-manual": "Monthly Manual Data",
  "/sScheduleAnalyzer": "Supervisor Schedule Analyzer",
  "/weekly-sales-report": "Weekly Sales Report",
  "/ee-data-upload": "TPC Data Upload",
  "/tz-file-upload": "TZ File Upload",
  "/fraud-prevention": "Fraud Prevention",
  "/monthly-processing": "Monthly Processing",
  "/grading-standards": "PoPs Standards",
  "/vision-dental": "Vision & Dental Processor",
  "/direct-deposit-change": "TPC Employee Change Analyzer",
  "/tzv2-stores": "TZv2 Store Overview",
  // ... add more paths if needed ...
};

/** 
 * A small helper for dropdown sections
 * 
 * @param {boolean} isLast  - If true, anchor the dropdown from the right 
 *                            instead of left. 
 */
function DropdownSection({ label, items, isLast = false }) {
  const [open, setOpen] = useState(false);
  const closeTimeout = useRef(null);
  const CLOSE_DELAY = 75; // ms

  const handleMouseEnter = () => {
    if (closeTimeout.current) {
      clearTimeout(closeTimeout.current);
      closeTimeout.current = null;
    }
    setOpen(true);
  };

  const handleMouseLeave = () => {
    closeTimeout.current = setTimeout(() => {
      setOpen(false);
      closeTimeout.current = null;
    }, CLOSE_DELAY);
  };

  // Conditionally apply style: anchor from left or right
  const dropdownPositionStyle = isLast ? { right: 0 } : { left: 0 };

  return (
    <Box
      position="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Button variant="ghost" size="sm" _hover={{ bg: "gray.100" }}>
        {label}
      </Button>

      {open && (
        <Box
          position="absolute"
          top="40px"
          // Conditionally anchor from the left or right
          {...dropdownPositionStyle}
          bg="white"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          shadow="md"
          p={2}
          minW="180px"
          maxW="calc(100vw - 1rem)"
          overflowX="auto"
          zIndex={10}
          whiteSpace="nowrap"
        >
          {items.map((item) => (
            <Box
              key={item.to}
              as={RouterLink}
              to={item.to}
              display="block"
              px={3}
              py={2}
              _hover={{ bg: "gray.50" }}
              onClick={() => setOpen(false)}
            >
              {item.label}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}

function NavBar({ username, userGroup }) {
  // ------------------------------------
  // EASY-TO-ADJUST POSITIONING VARIABLES
  // ------------------------------------
  const TITLE_MARGIN_LEFT = 3;       // Gap between logo and divider+title
  const DIVIDER_MARGIN_TOP = "12px"; // Moves the divider "|" up or down
  const TITLE_MARGIN_TOP = "18px";   // Moves the title text up or down

  const SEPARATOR_FONT_SIZE = "2xl";
  const TITLE_FONT_SIZE = "md";

  // Determine the current path & lookup the page title
  const location = useLocation();
  const currentPath = location.pathname;
  const pageTitle = routeTitles[currentPath] || "";

  // Normalize user info
  const normalizedName = username?.toLowerCase() || "";
  const normalizedGroup = userGroup?.toLowerCase() || "";

  // Identify special users
  const isJboltin = normalizedName === "jboltin";
  const isJnauertc = normalizedName === "jnauertc";
  const ispcartwright = normalizedName === "pcartwright";

  // Example logic for which user sees what
  const showPayrollProcessing = true;
  const showStoreOperations =
    isJboltin ||
    storeOperationsUsers.some((u) => u.toLowerCase() === normalizedName);
  const showSupervisor =
    normalizedGroup === "supervisor" ||
    isJboltin ||
    ispcartwright ||
    normalizedName === "jnauertc";
  const showDirector =
    normalizedGroup === "director" ||
    isJboltin;
  const showOffice =
    normalizedGroup === "office" ||
    normalizedName === "mparker" ||
    isJboltin;
  const showWorkInProgress =
    normalizedName === "jnauertc" || isJboltin;
  const showAdmin =
    normalizedGroup === "admin" || isJboltin;

  // New: Only JBoltiN or JNauertc can see ProlifiField as a direct link
  const showProlifiField = isJboltin || isJnauertc;

  // Define allowed supervisors for ProlifiField in the Supervisor dropdown
  const allowedProlifiFieldSupervisors = [
    "stutton",
    "cswafford",
    "c.cook",
    "jnauertc",
	"jboltin",
  ];

  // Build an array of the nav sections we want to show
  // Some sections will be dropdowns, some will be single links
  const navSections = [];

  // 1) Example "Payroll Processing" as a dropdown
  if (showPayrollProcessing) {
    navSections.push({
      type: "dropdown",
      label: "Payroll Processing",
      items: [
        { to: "/prFileUpload", label: "Payroll Converter" },
        { to: "/pdfReview", label: "Post-Payroll Reports" },
      ],
    });
  }

  // 2) "Store Operations" as a dropdown
  if (showStoreOperations) {
    navSections.push({
      type: "dropdown",
      label: "Store Operations",
      items: [{ to: "/csv-converter", label: "Truck Converter" }],
    });
  }

  // 3) "Supervisor Functions" as a dropdown
  if (showSupervisor) {
    // Build the supervisor menu items
    const supervisorItems = [
      { to: "/bbUpload", label: "BlueBook Upload" },
      { to: "/pops-report", label: "Pops Report" },
      { to: "/tzv2-stores", label: "TZv2 Store Dashboard" },
      { to: "/w-manual", label: "Weekly Manual Data" },
      { to: "/monthly-manual", label: "Monthly Manual Data" },
    ];

    // Add ProlifiField for allowed supervisors only
    if (allowedProlifiFieldSupervisors.includes(normalizedName)) {
      supervisorItems.push({ to: "/prolifi-schedules", label: "ProlifiSchedules" });
    }

    navSections.push({
      type: "dropdown",
      label: "Supervisor Functions",
      items: supervisorItems,
    });
  }

  // 4) "Director Functions" as a dropdown
  if (showDirector) {
    navSections.push({
      type: "dropdown",
      label: "Director Functions",
      items: [
		{ to: "/prolifi-schedules", label: "ProlifiSchedules" },
        { to: "/sScheduleAnalyzer", label: "Schedule Analyzer" },
        { to: "/weekly-sales-report", label: "Weekly Sales Report" },
        { to: "/ee-data-upload", label: "TPC Data Upload" },
        { to: "/tzv2-stores", label: "TZv2 Store Dashboard" },
        { to: "/tz-file-upload", label: "TZ File Upload" },
        { to: "/fraud-prevention", label: "Fraud Prevention" },
        { to: "/monthly-processing", label: "Monthly Processing" },
        { to: "/pops-report", label: "Pops Report" },
        { to: "/grading-standards", label: "PoPs Standards" },
      ],
    });
  }

  // 5) "Office" as a dropdown
  if (showOffice) {
    navSections.push({
      type: "dropdown",
      label: "Office",
      items: [
        { to: "/vision-dental", label: "Vision & Dental" },
        { to: "/direct-deposit-change", label: "Analyze Employee Change" },
      ],
    });
  }

  // 6) "Work-In-Progress" as a dropdown
  if (showWorkInProgress) {
    navSections.push({
      type: "dropdown",
      label: "Work-In-Progress",
      items: [
		{ to: "/pops-report-v2", label: "Pops Report 2.0" },
        { to: "/cr80-card-printer", label: "CR80 Card Printer" },
        { to: "/sales-forecaster", label: "Sales Forecaster" },
        { to: "/weather-data-importer", label: "Weather Data Importer" },
        { to: "/sticker-component", label: "Sticker Component" },
        { to: "/store-user-management", label: "Store User Management" },
        { to: "/corrective-action-dashboard", label: "Corrective Action Dashboard" },
        { to: "/shift-analyze", label: "Excel Uploader" },
        { to: "/employee-analyzer", label: "Employee Analyzer" },
        { to: "/employee-data-viewer", label: "Employee Affinity" },
        { to: "/closing-checklist-builder", label: "Closing Checklist Builder" },
      ],
    });
  }

  // 7) "Admin" as a dropdown
  if (showAdmin) {
    navSections.push({
      type: "dropdown",
      label: "Admin",
      items: [
        { to: "/create-user", label: "Create User" },
        { to: "/session-checker", label: "Session Checker" },
      ],
    });
  }

  // 8) ProlifiField as a direct link (no dropdown)
  if (showProlifiField) {
    navSections.push({
      type: "link",
      label: "ProlifiField",
      path: "/prolifi-field",
    });
  }

  return (
    <Box
      as="nav"
      bg="white"
      height="60px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      borderBottom="1px solid"
      borderColor="gray.200"
      px={6}
      shadow="sm"
    >
      {/* Left side: Logo, optional title */}
      <Flex alignItems="center">
        <Image
          src={ptechlogo}
          alt="Prolifi Logo"
          maxH="36px"
          objectFit="contain"
        />

        {/* If there's a page title, show divider + title */}
        {pageTitle && (
          <Flex align="center" ml={TITLE_MARGIN_LEFT}>
            <Text
              color="gray.300"
              fontSize={SEPARATOR_FONT_SIZE}
              fontWeight="bold"
              mr={3}
              mt={DIVIDER_MARGIN_TOP}
            >
              |
            </Text>
            <Text
              color="gray.600"
              fontSize={TITLE_FONT_SIZE}
              fontWeight="medium"
              mt={TITLE_MARGIN_TOP}
            >
              {pageTitle}
            </Text>
          </Flex>
        )}
      </Flex>

      {/* Right side: nav sections */}
      <Flex gap={4} alignItems="center">
        {navSections.map((section, idx) => {
          // If there is a 'path', we render a direct link instead of a dropdown
          if (section.type === "link") {
            return (
              <Button
                as={RouterLink}
                to={section.path}
                variant="ghost"
                size="sm"
                _hover={{ bg: "gray.100" }}
                key={section.label}
              >
                {section.label}
              </Button>
            );
          }
          // otherwise, it's a dropdown
          const isLastItem = idx === navSections.length - 1;
          return (
            <DropdownSection
              key={section.label}
              label={section.label}
              items={section.items}
              isLast={isLastItem}
            />
          );
        })}
      </Flex>
    </Box>
  );
}

export default NavBar;
