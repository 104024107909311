// src/QRGuts.js

import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { getBaseUrl } from "../utils/getBaseUrl";

// Firebase imports
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase"; // your firebase.js config

// We need jsPDF & html2canvas
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

// Import your existing components:
import Cr80Card from "./Cr80Card";
import Cr80CardLayout from "./Cr80CardLayout";
import Cr80CardBack from "./Cr80CardBack";

/**
 * QRGuts
 * - Fetches codes (e.g., 25) from your backend.
 * - Lets you add them to Firestore on demand.
 * - Builds multiple PDFs (fronts, backs, combined).
 * - Exports codes/links to CSV with an order-selection modal.
 */
function QRGuts() {
  const [codes, setCodes] = useState([]);
  const [alerted, setAlerted] = useState(false);

  // For the CSV modal
  const [showCsvModal, setShowCsvModal] = useState(false);

  // Refs for front/back offscreen rendering
  const frontRefs = useRef([]);
  const backRefs = useRef([]);

  // CR80 in portrait: 3.375" x 2.125" => 243 x 153 points
  // We'll use jsPDF in landscape orientation with [243, 153].
  const CR80_WIDTH = 243;
  const CR80_HEIGHT = 153;

  /**
   * After codes are updated, re-init the front/back refs so we have
   * one ref per code for screenshotting.
   */
  useEffect(() => {
    frontRefs.current = Array(codes.length)
      .fill()
      .map((_, i) => frontRefs.current[i] || React.createRef());
    backRefs.current = Array(codes.length)
      .fill()
      .map((_, i) => backRefs.current[i] || React.createRef());
  }, [codes.length]);

  /**
   * 1) Generate codes from your backend
   */
  const handleGenerateCodes = async () => {
    try {
      const response = await axios.post(`${getBaseUrl()}/cr80-codes/generate`);
      if (response.data?.codes) {
        console.log("Generated Codes:", response.data.codes);
        setCodes(response.data.codes);

        if (!alerted) {
          alert("Check console for the newly generated codes!");
          setAlerted(true);
        }
      }
    } catch (error) {
      console.error("Error generating codes:", error);
      alert("Error generating codes - see console for details.");
    }
  };

  /**
   * 2) Add codes to Firestore's
   *    /clients/unassigned/nfcTags/{code}.
   */
  const handleAddCodesToDb = async () => {
    if (!codes.length) {
      alert("No codes to add. Please generate codes first.");
      return;
    }

    try {
      for (let i = 0; i < codes.length; i++) {
        const code = codes[i];
        // Example fields:
        await setDoc(doc(db, "clients", "unassigned", "nfcTags", code), {
          assignedAt: serverTimestamp(),
          clientId: "unassigned",
          description: "",
          lastHit: null,
          tagId: code,
          totalHits: 0,
          totalYes: 0,
        });
        console.log(`Successfully wrote code '${code}' to Firestore.`);
      }
      alert("All codes have been added to Firestore under /clients/unassigned/nfcTags.");
    } catch (err) {
      console.error("Error adding codes to Firestore:", err);
      alert("Error adding codes to Firestore. Check console for details.");
    }
  };

  /**
   * Utility: screenshot all front cards (in normal order) → array of base64 images
   */
  const captureFrontImages = async () => {
    const images = [];
    for (let i = 0; i < codes.length; i++) {
      const refEl = frontRefs.current[i]?.current;
      if (!refEl) continue;

      const canvas = await html2canvas(refEl, {
        backgroundColor: null,
        scale: 3,
      });
      images.push(canvas.toDataURL("image/png"));
    }
    return images;
  };

  /**
   * Utility: screenshot all back cards (in normal order) → array of base64 images
   */
  const captureBackImages = async () => {
    const images = [];
    for (let i = 0; i < codes.length; i++) {
      const refEl = backRefs.current[i]?.current;
      if (!refEl) continue;

      const canvas = await html2canvas(refEl, {
        backgroundColor: null,
        scale: 3,
      });
      images.push(canvas.toDataURL("image/png"));
    }
    return images;
  };

  /**
   * 3) Build a single PDF with all fronts in normal order,
   *    with a blank page between each front.
   */
  const downloadAllFrontsPdf = async () => {
    if (!codes.length) return;

    try {
      const frontImages = await captureFrontImages();

      const doc = new jsPDF({
        orientation: "landscape",
        unit: "pt",
        format: [CR80_WIDTH, CR80_HEIGHT],
      });

      // Page 1: frontImages[0]
      doc.addImage(frontImages[0], "PNG", 0, 0, CR80_WIDTH, CR80_HEIGHT);

      // For each subsequent front, add blank -> page -> front image
      for (let i = 1; i < frontImages.length; i++) {
        doc.addPage(); // blank
        doc.addPage(); // next front
        doc.addImage(frontImages[i], "PNG", 0, 0, CR80_WIDTH, CR80_HEIGHT);
      }

      doc.save("all-fronts.pdf");
    } catch (err) {
      console.error("Error generating all-fronts PDF:", err);
      alert("Could not create all-fronts PDF");
    }
  };

  /**
   * 4) Build a single PDF with all backs in normal order (NO reversing),
   *    each preceded by a blank page:
   *    Page1 => blank, Page2 => back0,
   *    Page3 => blank, Page4 => back1, etc.
   */
  const downloadAllBacksPdf = async () => {
    if (!codes.length) return;

    try {
      const backImages = await captureBackImages();

      const doc = new jsPDF({
        orientation: "landscape",
        unit: "pt",
        format: [CR80_WIDTH, CR80_HEIGHT],
      });

      // Page1 => blank, Page2 => backImages[0]
      doc.addPage(); // blank
      doc.addPage(); // back of code[0]
      doc.addImage(backImages[0], "PNG", 0, 0, CR80_WIDTH, CR80_HEIGHT);

      // For each subsequent back, blank -> new page -> back i
      for (let i = 1; i < backImages.length; i++) {
        doc.addPage(); // blank
        doc.addPage(); // next back
        doc.addImage(backImages[i], "PNG", 0, 0, CR80_WIDTH, CR80_HEIGHT);
      }

      doc.save("all-backs.pdf");
    } catch (err) {
      console.error("Error generating all-backs PDF:", err);
      alert("Could not create all-backs PDF");
    }
  };

  /**
   * 5) Build a single PDF with front & back for each card in one go (normal order).
   */
  const downloadFrontAndBackPdf = async () => {
    if (!codes.length) return;

    try {
      const frontImages = await captureFrontImages();
      const backImages = await captureBackImages();

      const doc = new jsPDF({
        orientation: "landscape",
        unit: "pt",
        format: [CR80_WIDTH, CR80_HEIGHT],
      });

      // code0 => front, then back
      doc.addImage(frontImages[0], "PNG", 0, 0, CR80_WIDTH, CR80_HEIGHT);
      doc.addPage();
      doc.addImage(backImages[0], "PNG", 0, 0, CR80_WIDTH, CR80_HEIGHT);

      // subsequent codes => front -> back
      for (let i = 1; i < codes.length; i++) {
        doc.addPage();
        doc.addImage(frontImages[i], "PNG", 0, 0, CR80_WIDTH, CR80_HEIGHT);
        doc.addPage();
        doc.addImage(backImages[i], "PNG", 0, 0, CR80_WIDTH, CR80_HEIGHT);
      }

      doc.save("front-and-back.pdf");
    } catch (err) {
      console.error("Error generating front-and-back PDF:", err);
      alert("Could not create front-and-back PDF");
    }
  };

  const handleOpenCsvModal = () => {
    setShowCsvModal(true);
  };

  const handleCloseCsvModal = () => {
    setShowCsvModal(false);
  };

  const handleDownloadCsv = (order) => {
    let finalCodes = [...codes];
    if (order === "reverse") {
      finalCodes.reverse();
    }

    // Build CSV lines: "Code,Link"
    const csvRows = ["Code,Link\n"];
    finalCodes.forEach((code) => {
      const url = `https://truemetric.io/?tagId=${code}`;
      csvRows.push(`${code},${url}\n`);
    });

    const csvContent = csvRows.join("");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const csvUrl = URL.createObjectURL(blob);

    const hiddenLink = document.createElement("a");
    hiddenLink.href = csvUrl;
    hiddenLink.setAttribute("download", "codes.csv");
    document.body.appendChild(hiddenLink);
    hiddenLink.click();
    document.body.removeChild(hiddenLink);

    setShowCsvModal(false);
  };

  /**
   * Button & layout styling
   */
  const [hoverIndex, setHoverIndex] = useState(null);

  const buttonStyle = {
    background: "#4c6ef5",
    color: "#fff",
    padding: "0.6rem 1.2rem",
    margin: "0.2rem",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "0.95rem",
    fontWeight: 500,
    transition: "background 0.2s ease-in-out",
  };

  const buttonHoverStyle = {
    background: "#3759d6",
  };

  const containerStyle = {
    maxWidth: "800px",
    margin: "2rem auto",
    padding: "2rem",
    background: "#f3f4f6",
    borderRadius: "8px",
    boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
    fontFamily: `'Roboto', sans-serif`,
  };

  const headingStyle = {
    fontSize: "1.8rem",
    marginBottom: "1rem",
    color: "#333",
    textAlign: "center",
  };

  const subheadingStyle = {
    marginTop: "1rem",
    fontSize: "1rem",
    color: "#555",
    textAlign: "center",
  };

  // Modal styling
  const modalOverlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 9999,
  };

  const modalContentStyle = {
    background: "#fff",
    padding: "2rem",
    borderRadius: "8px",
    width: "320px",
    textAlign: "center",
  };

  const modalButtonContainerStyle = {
    marginTop: "1.5rem",
    display: "flex",
    justifyContent: "space-around",
  };

  return (
    <div style={containerStyle}>
      <h2 style={headingStyle}>Bulk CR80 PDF Generation</h2>

      <div style={{ textAlign: "center", marginBottom: "1.5rem" }}>
        <button
          style={{
            ...buttonStyle,
            ...(hoverIndex === "generate" ? buttonHoverStyle : {}),
          }}
          onMouseOver={() => setHoverIndex("generate")}
          onMouseOut={() => setHoverIndex(null)}
          onClick={handleGenerateCodes}
        >
          Generate Codes
        </button>
      </div>

      {codes.length > 0 && (
        <>
          <p style={{ textAlign: "center" }}>
            <strong>Codes:</strong> {codes.join(", ")}
          </p>

          <div style={{ textAlign: "center", marginTop: "1.5rem" }}>
            {/* Add Codes to DB */}
            <button
              style={{
                ...buttonStyle,
                ...(hoverIndex === "addToDb" ? buttonHoverStyle : {}),
              }}
              onMouseOver={() => setHoverIndex("addToDb")}
              onMouseOut={() => setHoverIndex(null)}
              onClick={handleAddCodesToDb}
            >
              Add Codes to DB
            </button>

            {/* PDF generation: fronts, backs, combined */}
            <button
              style={{
                ...buttonStyle,
                ...(hoverIndex === "fronts" ? buttonHoverStyle : {}),
              }}
              onMouseOver={() => setHoverIndex("fronts")}
              onMouseOut={() => setHoverIndex(null)}
              onClick={downloadAllFrontsPdf}
            >
              All Fronts (Normal)
            </button>

            <button
              style={{
                ...buttonStyle,
                ...(hoverIndex === "backs" ? buttonHoverStyle : {}),
              }}
              onMouseOver={() => setHoverIndex("backs")}
              onMouseOut={() => setHoverIndex(null)}
              onClick={downloadAllBacksPdf}
            >
              All Backs (Normal)
            </button>

            <button
              style={{
                ...buttonStyle,
                ...(hoverIndex === "frontAndBack" ? buttonHoverStyle : {}),
              }}
              onMouseOver={() => setHoverIndex("frontAndBack")}
              onMouseOut={() => setHoverIndex(null)}
              onClick={downloadFrontAndBackPdf}
            >
              Front &amp; Back in One PDF
            </button>

            {/* CSV Button */}
            <button
              style={{
                ...buttonStyle,
                ...(hoverIndex === "csv" ? buttonHoverStyle : {}),
              }}
              onMouseOver={() => setHoverIndex("csv")}
              onMouseOut={() => setHoverIndex(null)}
              onClick={() => setShowCsvModal(true)}
            >
              Download CSV
            </button>
          </div>

          <p style={subheadingStyle}>
            Use whichever approach you prefer for printing.
            <br />
            <em>Front/Back combined</em> is for single-ribbon printing.
          </p>
        </>
      )}

      {/*
        Hidden offscreen containers for each code's front and back.
        We'll capture them with html2canvas for the PDFs.
      */}
      <div style={{ position: "absolute", left: "-9999px", top: 0 }}>
        {codes.map((code, idx) => (
          <div key={`front-${idx}`} ref={frontRefs.current[idx]}>
            <Cr80Card>
              <Cr80CardLayout qrLink={code} />
            </Cr80Card>
          </div>
        ))}
      </div>

      <div style={{ position: "absolute", left: "-9999px", top: "300px" }}>
        {codes.map((code, idx) => (
          <div key={`back-${idx}`} ref={backRefs.current[idx]}>
            <Cr80Card>
              <Cr80CardBack code={code} />
            </Cr80Card>
          </div>
        ))}
      </div>

      {/* CSV Modal */}
      {showCsvModal && (
        <div style={modalOverlayStyle}>
          <div style={modalContentStyle}>
            <h3>Choose CSV Order</h3>
            <p>Would you like the codes in original or reversed order?</p>
            <div style={modalButtonContainerStyle}>
              <button style={buttonStyle} onClick={() => handleDownloadCsv("original")}>
                Original
              </button>
              <button style={buttonStyle} onClick={() => handleDownloadCsv("reverse")}>
                Reverse
              </button>
            </div>
            <div style={{ marginTop: "1rem" }}>
              <button
                onClick={() => setShowCsvModal(false)}
                style={{
                  ...buttonStyle,
                  background: "#777",
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default QRGuts;
