import React from "react";
import trueMetricLogo from "../components/images/TrueMetric.png";

/**
 * Back side of the CR80 card:
 *   - Transparent background
 *   - TrueMetric logo in the center
 *   - Small text (the code) below the logo
 */
function Cr80CardBack({ code }) {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        backgroundColor: "transparent",
      }}
    >
      {/* TrueMetric logo centered */}
      <img
        src={trueMetricLogo}
        alt="TrueMetric"
        style={{
          position: "absolute",
          top: "55%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "220px",
        }}
      />

      {/* The code below the logo */}
      <div
        style={{
          position: "absolute",
          top: "81%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "12px",
          textAlign: "center",
        }}
      >
        {code}
      </div>
    </div>
  );
}

export default Cr80CardBack;
