// formatExcelReportv2.js
import ExcelJS from 'exceljs';
import * as gradeKPI from './GradeKPIsv2';  // Namespace import ensures we get all exported functions

// Helper function: Convert minutes (number) to "h:mm" format.
function convertMinutesToTimeStr(minutes) {
  const total = Number(minutes);
  if (isNaN(total)) return minutes;
  const hours = Math.floor(total / 60);
  const mins = Math.round(total % 60);
  return `${hours}:${mins < 10 ? '0' : ''}${mins}`;
}

// Map grade colors to ExcelJS ARGB color codes.
const colorMap = {
  green: 'FF00FF00',   // Bright green
  yellow: 'FFFFFF00',  // Yellow
  red: 'FFFF0000',     // Red
  default: 'FFFFFFFF', // White
};

export const formatExcelReport = (worksheet, reportData, selectedMonth, selectedArea, gradingStandards) => {
  // Title row (merged)
  worksheet.mergeCells('A2:M2');
  worksheet.getCell('A2').value = `${selectedMonth}`;
  worksheet.getCell('A2').font = { size: 14, bold: true };
  worksheet.getCell('A2').alignment = { horizontal: 'center' };
  worksheet.getCell('A2').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFFFF' },
  };

  // Report header (merged)
  worksheet.mergeCells('A1:M1');
  worksheet.getCell('A1').value = `${selectedArea} Pops Report`;
  worksheet.getCell('A1').font = { size: 16, bold: true };
  worksheet.getCell('A1').alignment = { horizontal: 'center' };
  worksheet.getCell('A1').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFFFF' },
  };

  // Define columns using keys matching your backend data.
  worksheet.columns = [
    { header: 'Rank', key: 'overallRank', width: 5 },
    { header: 'Store Name', key: 'storeName', width: 15 },
    { header: 'MTD Sales', key: 'mtdSales', width: 15 },
    { header: 'Sales % Change', key: 'salesPctChange', width: 15 },
    { header: 'Controllables', key: 'contExpenses', width: 15 },
    { header: 'Production', key: 'crewProduction', width: 12 },
    { header: 'Labor %', key: 'laborPct', width: 10 },
    { header: 'Variance', key: 'foodVariance', width: 10 },
    { header: 'On Times %', key: 'onTimesPct', width: 10 },
    { header: 'Ave. Time', key: 'avgTime', width: 12 },
    { header: 'Reply Time', key: 'replyTime', width: 12 },
    { header: 'Complaints/10k', key: 'complaintsPer10K', width: 10 },
    { header: 'Cash', key: 'cash', width: 15 },
  ];

  // Set header row styling (Row 3)
  const headerRow = worksheet.getRow(3);
  worksheet.columns.forEach((col, index) => {
    const cell = headerRow.getCell(index + 1);
    cell.value = col.header;
    cell.font = { bold: true };
    cell.alignment = { horizontal: 'center' };
    cell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  });
  headerRow.commit();

  // Add data rows starting from row 4.
  reportData.forEach((row) => {
    const dataRow = worksheet.addRow({
      overallRank: row.overallRank,
      storeName: row.storeName,
      mtdSales: row.mtdSales,
      salesPctChange: row.salesPctChange,
      contExpenses: row.contExpenses,
      crewProduction: row.crewProduction,
      laborPct: row.laborPct,
      foodVariance: row.foodVariance,
      onTimesPct: row.onTimesPct,
      avgTime: row.avgTime,      // In minutes
      replyTime: row.replyTime,  // In minutes
      complaintsPer10K: row.complaintsPer10K,
      cash: row.cash,
    });

    // Apply conditional formatting using gradeKPI.
    // KPI columns: columns 4 to 13.
    const kpiKeys = ['salesPctChange', 'contExpenses', 'crewProduction', 'laborPct', 'foodVariance', 'onTimesPct', 'avgTime', 'replyTime', 'complaintsPer10K', 'cash'];
    kpiKeys.forEach((key, index) => {
      const colIndex = index + 4; // Columns 1-3 are Rank, Store Name, MTD Sales.
      const value = row[key];
      const gradeResult = gradeKPI[key](value, gradingStandards[key] || {});
      const argb = colorMap[gradeResult.color] || colorMap.default;
      const cell = dataRow.getCell(colIndex);
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb },
      };
    });

    // Format each column:
    // MTD Sales as currency.
    const mtdSalesCell = dataRow.getCell('mtdSales');
    mtdSalesCell.value = Number(row.mtdSales);
    mtdSalesCell.numFmt = '$#,##0.00';

    // Sales % Change, Labor %, On Times % as percentages.
    const salesPctCell = dataRow.getCell('salesPctChange');
    salesPctCell.value = Number(row.salesPctChange) / 100;
    salesPctCell.numFmt = '0.00%';

    const laborPctCell = dataRow.getCell('laborPct');
    laborPctCell.value = Number(row.laborPct) / 100;
    laborPctCell.numFmt = '0.00%';

    const onTimesPctCell = dataRow.getCell('onTimesPct');
    onTimesPctCell.value = Number(row.onTimesPct) / 100;
    onTimesPctCell.numFmt = '0.00%';

    // Controllables (contExpenses) as a percentage.
    const contExpensesCell = dataRow.getCell('contExpenses');
    contExpensesCell.value = Number(row.contExpenses) / 100;
    contExpensesCell.numFmt = '0.00%';

    // Crew Production as currency.
    const crewProductionCell = dataRow.getCell('crewProduction');
    crewProductionCell.value = Number(row.crewProduction);
    crewProductionCell.numFmt = '$#,##0.00';

    // Complaints/10k rounded to two decimals.
    const complaintsCell = dataRow.getCell('complaintsPer10K');
    complaintsCell.value = Number(row.complaintsPer10K);
    complaintsCell.numFmt = '0.00';

    // Cash as currency.
    const cashCell = dataRow.getCell('cash');
    cashCell.value = Number(row.cash);
    cashCell.numFmt = '$#,##0.00';

    // Average Time and Reply Time: Convert minutes to "h:mm" format.
    const avgTimeCell = dataRow.getCell('avgTime');
    avgTimeCell.value = convertMinutesToTimeStr(row.avgTime);
    const replyTimeCell = dataRow.getCell('replyTime');
    replyTimeCell.value = convertMinutesToTimeStr(row.replyTime);

    // Align "Store Name" to right.
    dataRow.getCell('storeName').alignment = { horizontal: 'right' };

    // Center align all cells in the row and apply thin borders.
    dataRow.eachCell({ includeEmpty: true }, (cell) => {
      cell.alignment = { horizontal: 'center' };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
  });

  // Apply borders to all rows.
  const lastRow = worksheet.lastRow.number;
  for (let rowNum = 1; rowNum <= lastRow; rowNum++) {
    const row = worksheet.getRow(rowNum);
    row.eachCell({ includeEmpty: true }, (cell) => {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
  }
};
