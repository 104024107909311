// src/RightPanel.js
import React, { useState, useEffect } from "react";
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  doc, 
  setDoc, 
  serverTimestamp 
} from "firebase/firestore";
import { db } from "../firebase"; // adjust the path as needed

function RightPanel() {
  // State to hold the list of clients (clientId and displayName)
  const [clients, setClients] = useState([]);
  // State to track the currently selected client (clientId)
  const [selectedClient, setSelectedClient] = useState("");

  // Form state for new NFC entry
  const [assignedTo, setAssignedTo] = useState("New Card");
  const [placement, setPlacement] = useState("Person");
  const [tagId, setTagId] = useState("");

  useEffect(() => {
    const fetchSonicClients = async () => {
      try {
        console.log("Fetching Sonic clients from Firestore (clients collection)...");

        // Query the "clients" collection for documents where reviewPlatform === "Sonic"
        const clientsRef = collection(db, "clients");
        const sonicQuery = query(clientsRef, where("reviewPlatform", "==", "Sonic"));
        const querySnapshot = await getDocs(sonicQuery);
        console.log(`Query returned ${querySnapshot.size} documents.`);

        const clientsList = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const displayName = data.displayName;
          console.log("Processing client document:", doc.id, data);
          // Skip any client with displayName "1aCardHolder"
          if (displayName && displayName !== "1aCardHolder") {
            clientsList.push({ clientId: doc.id, displayName });
            console.log(`Added client: ${doc.id} - ${displayName}`);
          } else {
            console.log(`Skipped client ${doc.id} with displayName "${displayName}"`);
          }
        });

        console.log("Final clients list:", clientsList);
        setClients(clientsList);
      } catch (error) {
        console.error("Error fetching Sonic clients:", error);
      }
    };

    fetchSonicClients();
  }, []);

  const handleClientChange = (e) => {
    setSelectedClient(e.target.value);
    console.log("Selected client:", e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedClient) {
      alert("Please select a client.");
      return;
    }
    if (!tagId) {
      alert("Please enter a Tag ID.");
      return;
    }

    // The document ID will be the tagId entered by the user.
    const docRef = doc(db, "clients", selectedClient, "nfcTags", tagId);
    try {
      await setDoc(docRef, {
        assignedAt: serverTimestamp(),
        assignedTo: assignedTo,
        clientId: selectedClient,
        id: tagId, // same as tagId
        placement: placement,
        tagId: tagId,
        // Other fields such as description, totalHits, totalNo, totalYes, etc. are omitted
      });
      console.log("Document successfully written!");
      alert("Entry created successfully!");

      // Reset form fields
      setAssignedTo("New Card");
      setPlacement("Person");
      setTagId("");
    } catch (error) {
      console.error("Error writing document: ", error);
      alert("Error creating document.");
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#f9f9f9",
        padding: "20px",
        border: "1px solid #ddd",
        borderRadius: "8px",
        height: "100%", // optional: ensures it fills its container
      }}
    >
      <h2>Activate QR/NFC Codes</h2>
      <p>Select a Sonic client to activate:</p>
      <select value={selectedClient} onChange={handleClientChange}>
        <option value="">Select a client</option>
        {clients.map((client) => (
          <option key={client.clientId} value={client.clientId}>
            {client.displayName}
          </option>
        ))}
      </select>
      {selectedClient && (
        <p style={{ marginTop: "1rem" }}>
          Selected client ID: <strong>{selectedClient}</strong>
        </p>
      )}

      {/* New form for inputting additional NFC data */}
      <form onSubmit={handleSubmit} style={{ marginTop: "2rem" }}>
        <div style={{ marginBottom: "1rem" }}>
          <label style={{ display: "block", marginBottom: "0.3rem" }}>Assigned To:</label>
          <input
            type="text"
            value={assignedTo}
            onChange={(e) => setAssignedTo(e.target.value)}
            style={{ width: "100%", padding: "0.4rem" }}
          />
        </div>

        <div style={{ marginBottom: "1rem" }}>
          <label style={{ display: "block", marginBottom: "0.3rem" }}>Placement:</label>
          <select
            value={placement}
            onChange={(e) => setPlacement(e.target.value)}
            style={{ width: "100%", padding: "0.4rem" }}
          >
            <option value="Person">Person</option>
            <option value="Stall/DT">Stall/DT</option>
          </select>
        </div>

        <div style={{ marginBottom: "1rem" }}>
          <label style={{ display: "block", marginBottom: "0.3rem" }}>Tag ID:</label>
          <input
            type="text"
            value={tagId}
            onChange={(e) => setTagId(e.target.value)}
            style={{ width: "100%", padding: "0.4rem" }}
          />
        </div>

        <button
          type="submit"
          style={{
            backgroundColor: "#4c6ef5",
            color: "#fff",
            padding: "0.6rem 1.2rem",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            fontSize: "0.95rem",
            fontWeight: 500,
          }}
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default RightPanel;
