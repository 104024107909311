import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

import DirectorDashboardSection from './dDashboardSec'; // optional collapse bar
import DirectorScheduleSection from './DirectorScheduleSection';
import { getBaseUrl } from '../../../utils/getBaseUrl';
import './MyScheduler.css';

export default function DirectorSchedulerContainer() {
  const [dashboardCollapsed, setDashboardCollapsed] = useState(false);

  // We'll store date range in state. If empty, initialize to the current month on mount.
  const [startDate, setStartDate] = useState(null);
  const [endDate,   setEndDate]   = useState(null);

  const [events, setEvents] = useState([]);
  const [supervisorResource, setSupervisorResource] = useState([]);

  // Holds the array of director “areas” (e.g. ["Kansas & Nebraska", "Champaign", ...])
  const [directorStoreData, setDirectorStoreData] = useState([]);

  const DIRECTOR_RESOURCE_ID = -999;

  // If Director is also a supervisor => store that supId
  const [directorSupId, setDirectorSupId] = useState(null);

  // NEW: Track the Director’s display name for use in store-visit Subjects
  const [directorDisplayName, setDirectorDisplayName] = useState('Unknown Director');

  const scheduleSectionRef = useRef(null);

  // 1) On mount => if no start/end => set them to the current month
  useEffect(() => {
    if (!startDate && !endDate) {
      const now = new Date();
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0);
      const endOfMonth   = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);
      setStartDate(startOfMonth.toISOString());
      setEndDate(endOfMonth.toISOString());
    }
  }, [startDate, endDate]);

  // 2) Whenever date range changes => fetch from back end
  useEffect(() => {
    if (startDate && endDate) {
      fetchDirectorShifts(startDate, endDate);
    }
  }, [startDate, endDate]);

  async function fetchDirectorShifts(start, end) {
    const baseUrl = getBaseUrl();
    const url = `${baseUrl}/pfield_events/pfield_director/director_shifts`;
    const payload = { StartDate: start, EndDate: end };
    console.log('[fetchDirectorShifts] => posting:', payload);

    try {
      const res = await axios.post(url, payload, { withCredentials: true });
      const {
        supervisors = [],
        directorShifts = [],
        directorName   = 'Unknown Director',
        directorIsSupervisor = false,
        directorAreas = [],
      } = res.data || {};

      // Possibly label the director
      let finalDirectorName = directorName;
      if (directorIsSupervisor) {
        finalDirectorName += ' (Director)';
      }

      // Store the name for store-visit Subjects
      setDirectorDisplayName(finalDirectorName);

      // For store visits in the modal => store these “areas” in local state
      setDirectorStoreData(directorAreas);

      // Combine events => sup shifts + director shifts
      const combinedEvents = [];
      supervisors.forEach(sup => {
        sup.shifts.forEach(sh => {
          combinedEvents.push({
            Id:          sh.Id,
            SupervisorId: sup.supId,
            Subject:      sh.Subject,
            startTime:    sh.StartTime,
            endTime:      sh.EndTime,
            Description:  sh.Description,
            CombinedId:   sh.CombinedId,
            CategoryId:   sh.CategoryId
          });
        });
      });

      directorShifts.forEach(dr => {
        combinedEvents.push({
          Id:           dr.Id,
          SupervisorId: DIRECTOR_RESOURCE_ID,
          Subject:      dr.Subject,
          startTime:    dr.startTime,
          endTime:      dr.endTime,
          Description:  dr.Description,
          CombinedId:   dr.CombinedId,
          CategoryId:   dr.CategoryId,
          storeOrEvent: dr.storeOrEvent,
          storeIds:     dr.storeIds || [],
          notes:        dr.notes || []
        });
      });
      setEvents(combinedEvents);

      // Resource array => Director row first
      const directorResource = {
        id:   DIRECTOR_RESOURCE_ID,
        text: finalDirectorName,
      };

      // Distinct color for each supervisor
      const colorPalette = [
        '#2196f3','#FF9800','#4CAF50','#9C27B0','#f44336',
        '#795548','#E91E63','#3F51B5','#009688','#CDDC39',
      ];
      let colorIndex = 0;
      const supResources = supervisors.map(sup => {
        const color = colorPalette[colorIndex % colorPalette.length];
        colorIndex++;
        return {
          id:   sup.supId,
          text: sup.name,
          color
        };
      });

      setSupervisorResource([directorResource, ...supResources]);

      // If director is also a supervisor => find that sup's ID
      if (directorIsSupervisor) {
        const match = supervisors.find(s => s.name === directorName);
        if (match) {
          setDirectorSupId(match.supId);
        }
      }
    } catch (err) {
      console.error('Error fetching director shift data =>', err);
      setEvents([]);
      setSupervisorResource([]);
      setDirectorStoreData([]);
    }
  }

  /**
   * We make this async so the child can await it,
   * ensuring that the fetch is done before the schedule is refreshed.
   */
  async function handleEventsChanged() {
    if (startDate && endDate) {
      await fetchDirectorShifts(startDate, endDate);
    }
  }

  function toggleDashboard() {
    setDashboardCollapsed(prev => !prev);
  }

  function expandCollapseAndSwitchView(newView) {
    const oldCollapsed = dashboardCollapsed;
    setDashboardCollapsed(false);
    requestAnimationFrame(() => {
      if (scheduleSectionRef.current?.switchView) {
        scheduleSectionRef.current.switchView(newView);
      }
      requestAnimationFrame(() => {
        setDashboardCollapsed(oldCollapsed);
      });
    });
  }

  function handleDateRangeChange(start, end) {
    setStartDate(start);
    setEndDate(end);
  }

  return (
    <div style={styles.pageContainer}>
      <div style={{ width:'100%', position:'relative' }}>
        <DirectorDashboardSection
          collapsed={dashboardCollapsed}
          onToggle={toggleDashboard}
        />

        <DirectorScheduleSection
          ref={scheduleSectionRef}
          events={events}
          setEvents={setEvents}
          supervisorResource={supervisorResource}
          onDateRangeChange={handleDateRangeChange}
          expandCollapseAndSwitchView={expandCollapseAndSwitchView}
          startDate={startDate}
          endDate={endDate}
          onEventsChanged={handleEventsChanged}
          directorStores={directorStoreData}
          directorSupId={directorSupId}
          directorAreas={directorStoreData}

          // NEW PROP: pass the final director name for store-visit subjects
          directorName={directorDisplayName}
        />
      </div>
    </div>
  );
}

const styles = {
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: 1,
    padding: 1,
  }
};
