// File: src/components/ProlifiField/Schedules/super/ChakraVariantModal.jsx

import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Flex,
  VStack,
  HStack,
  Box,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon, AddIcon, DeleteIcon } from '@chakra-ui/icons';
import Select from 'react-select';
import axios from 'axios';
import { getBaseUrl } from '../../../../utils/getBaseUrl';

/**
 * Helper: Convert a Date to "YYYY-MM-DDTHH:mm" for <input type="datetime-local"/>
 */
function toDateTimeLocalString(date) {
  if (!(date instanceof Date) || isNaN(date.getTime())) return '';
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const dd = String(date.getDate()).padStart(2, '0');
  const hh = String(date.getHours()).padStart(2, '0');
  const min = String(date.getMinutes()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}T${hh}:${min}`;
}

/**
 * Helper: Return array of typical "Reasons" based on store/event ID
 */
function getReasonOptionsForCombinedId(combinedId) {
  if (!combinedId) return [];
  const lower = String(combinedId).toLowerCase();

  if (lower === 'vacation') {
    return [];
  }
  if (lower === 'office') {
    return ['End of Week', 'Schedule Review', 'Inventory Review', 'End of Month', 'Other'];
  }
  if (lower === 'meetings') {
    return ['Store/GM', 'Supervisor', 'Class: AMLC', 'Class: EGMC', 'Other'];
  }
  // Otherwise => assume normal store
  return [
    'General Visit',
    'Struggling Sales, Profit, or KPIs',
    'Audit/Quarterly',
    'Working w/ MIT',
    'GM not Present',
    'Other',
  ];
}

/**
 * ChakraVariantModal
 *
 * Props:
 *   - isOpen: boolean => controls modal visibility
 *   - variantShifts: array => e.g. [ { id, subject, start_time, end_time, ...}, ... ]
 *   - storeData: array => e.g. [ { store_id, store_name }, ... ]
 *   - onComplete: function(resultsArr) => callback with results:
 *         [ { id, didNotWork: boolean, actualShifts: [ lines ] }, ... ]
 *   - forceCloseVariant: function => a callback to forcibly close the modal
 *
 * Features:
 *   - "Mark as Not Worked" button is near "Add Another Shift" in the body.
 *   - If user marks a day as not worked, we show a small panel + “Undo Did Not Work” in the same position.
 *   - The first shift line in the list has no delete (trash) button, so user cannot remove the only line.
 */
export default function ChakraVariantModal({
  isOpen,
  variantShifts = [],
  storeData = [],
  onComplete,
  forceCloseVariant,
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [actualShiftsArr, setActualShiftsArr] = useState([]); // array of arrays
  const [didNotWorkFlags, setDidNotWorkFlags] = useState([]); // array of bools
  const toast = useToast();

  // On mount or when variantShifts changes, set up lines and flags
  useEffect(() => {
    if (!variantShifts || variantShifts.length === 0) {
      setCurrentIndex(0);
      setActualShiftsArr([]);
      setDidNotWorkFlags([]);
      return;
    }

    // For each shift, create an initial line using the original SHIFT times
    const initial = variantShifts.map((sh) => {
      let st = null;
      let et = null;
      if (sh.start_time) {
        const parsed = new Date(sh.start_time);
        if (!isNaN(parsed)) st = parsed;
      }
      if (sh.end_time) {
        const parsed = new Date(sh.end_time);
        if (!isNaN(parsed)) et = parsed;
      }
      return [
        {
          storeId: '',
          reasons: [],
          reasonOther: '',
          startTime: st,
          endTime: et,
        },
      ];
    });
    setActualShiftsArr(initial);
    setDidNotWorkFlags(new Array(variantShifts.length).fill(false));
    setCurrentIndex(0);
  }, [variantShifts]);

  if (!isOpen || variantShifts.length === 0) {
    return null;
  }
  if (currentIndex >= variantShifts.length) {
    // Safety check
    setCurrentIndex(0);
    return null;
  }

  // The shift we’re currently working with
  const shift = variantShifts[currentIndex];
  const lines = actualShiftsArr[currentIndex] || [];

  // Handler: update a field in a given line
  function handleLineChange(lineIdx, field, value) {
    setActualShiftsArr((prev) => {
      const newArr = [...prev];
      const lineArr = [...newArr[currentIndex]];
      lineArr[lineIdx] = { ...lineArr[lineIdx], [field]: value };
      newArr[currentIndex] = lineArr;
      return newArr;
    });
  }

  function handleReasonsChange(lineIdx, selectedOptions) {
    const arr = selectedOptions ? selectedOptions.map((o) => o.value) : [];
    handleLineChange(lineIdx, 'reasons', arr);
  }

  function handleDateTimeChange(lineIdx, field, e) {
    const dt = new Date(e.target.value);
    if (isNaN(dt.getTime())) {
      handleLineChange(lineIdx, field, null);
    } else {
      handleLineChange(lineIdx, field, dt);
    }
  }

  // Handler: add a new line
  function handleAddLine() {
    setActualShiftsArr((prev) => {
      const newOuter = [...prev];
      const lineArr = [...newOuter[currentIndex]];
      let defStart = null;
      let defEnd = null;
      if (lineArr.length > 0) {
        const lastLine = lineArr[lineArr.length - 1];
        if (lastLine.endTime instanceof Date && !isNaN(lastLine.endTime)) {
          defStart = new Date(lastLine.endTime);
          defEnd = new Date(lastLine.endTime.getTime() + 3 * 60 * 60 * 1000);
        }
      }
      lineArr.push({
        storeId: '',
        reasons: [],
        reasonOther: '',
        startTime: defStart,
        endTime: defEnd,
      });
      newOuter[currentIndex] = lineArr;
      return newOuter;
    });
  }

  // Handler: remove a line
  function handleRemoveLine(lineIdx) {
    setActualShiftsArr((prev) => {
      const newOuter = [...prev];
      const lineArr = [...newOuter[currentIndex]];
      lineArr.splice(lineIdx, 1);
      newOuter[currentIndex] = lineArr;
      return newOuter;
    });
  }

  // Navigation: previous/next shift
  function handlePrev() {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  }
  function handleNext() {
    if (currentIndex < variantShifts.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  }

  // Mark "did not work"
  function handleNoWork() {
    setDidNotWorkFlags((prev) => {
      const newFlags = [...prev];
      newFlags[currentIndex] = true;
      return newFlags;
    });
    // Clear the lines
    setActualShiftsArr((prev) => {
      const newArr = [...prev];
      newArr[currentIndex] = [];
      return newArr;
    });
    toast({
      title: 'Marked as did not work',
      description: 'We cleared all lines for this day.',
      status: 'info',
      duration: 3000,
      isClosable: true,
    });
  }

  // Undo "did not work"
  function handleUndoNoWork() {
    setDidNotWorkFlags((prev) => {
      const newFlags = [...prev];
      newFlags[currentIndex] = false;
      return newFlags;
    });

    // Re-add a single line with original times
    const orig = variantShifts[currentIndex];
    let st = null;
    let et = null;
    if (orig.start_time) {
      const parsed = new Date(orig.start_time);
      if (!isNaN(parsed)) st = parsed;
    }
    if (orig.end_time) {
      const parsed = new Date(orig.end_time);
      if (!isNaN(parsed)) et = parsed;
    }

    setActualShiftsArr((prev) => {
      const newArr = [...prev];
      newArr[currentIndex] = [
        {
          storeId: '',
          reasons: [],
          reasonOther: '',
          startTime: st,
          endTime: et,
        },
      ];
      return newArr;
    });

    toast({
      title: 'Did not work undone',
      description: 'You can now re-enter your actual shifts.',
      status: 'info',
      duration: 3000,
      isClosable: true,
    });
  }

  // Submit all
  function handleSubmitAll() {
    // Build final results for each shift
    const results = variantShifts.map((origShift, idx) => ({
      id: origShift.id,
      didNotWork: didNotWorkFlags[idx] || false,
      actualShifts: didNotWorkFlags[idx] ? [] : actualShiftsArr[idx],
    }));
    onComplete(results);
    if (typeof forceCloseVariant === 'function') {
      forceCloseVariant();
    }
  }

  // Validation
  function lineValid(line) {
    return (
      line.storeId &&
      line.startTime instanceof Date && !isNaN(line.startTime) &&
      line.endTime instanceof Date && !isNaN(line.endTime)
    );
  }
  const allLinesValid =
    didNotWorkFlags[currentIndex] || (lines.length > 0 && lines.every(lineValid));

  // Format the original shift date
  const startObj = shift.start_time ? new Date(shift.start_time) : null;
  const dateStr = startObj
    ? startObj.toLocaleDateString(undefined, { weekday: 'long', month: 'long', day: 'numeric' })
    : '';

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {}}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="4xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottomWidth="1px" borderColor="gray.200">
          You Did Not Work This Shift
        </ModalHeader>

        <ModalBody>
          {/* Navigation for multiple variant shifts */}
          <Flex justify="space-between" align="center" mb={3}>
            <IconButton
              icon={<ArrowLeftIcon />}
              aria-label="Prev shift"
              onClick={handlePrev}
              isDisabled={currentIndex === 0}
              size="sm"
            />
            <Text fontSize="sm">
              Shift {currentIndex + 1} of {variantShifts.length}
            </Text>
            <IconButton
              icon={<ArrowRightIcon />}
              aria-label="Next shift"
              onClick={handleNext}
              isDisabled={currentIndex === variantShifts.length - 1}
              size="sm"
            />
          </Flex>

          {/* Original shift info */}
          <Box
            p={2}
            bg="gray.50"
            borderRadius="md"
            mb={3}
            border="1px"
            borderColor="gray.200"
          >
            <Text fontWeight="bold" fontSize="md" mb={1}>
              Original: {shift.subject} – {dateStr}
            </Text>
            <Text fontSize="xs" color="gray.600">
              Start: {shift.start_time} / End: {shift.end_time}
            </Text>
          </Box>

          {/* If user said "did not work", show a panel; otherwise, lines + row */}
          {didNotWorkFlags[currentIndex] ? (
            <Box
              textAlign="center"
              p={6}
              borderRadius="md"
              border="2px dashed #999"
              bg="gray.50"
            >
              <Text fontSize="md" fontWeight="bold" color="red.600">
                This day is currently marked as not worked
              </Text>
              <Text mt={2} fontSize="sm">
                If you clicked “did not work” by mistake, you can undo it below.
              </Text>

              <HStack mt={4} justify="center">
                <Button
                  size="sm"
                  colorScheme="blue"
                  onClick={handleUndoNoWork}
                >
                  Undo Did Not Work
                </Button>
              </HStack>
            </Box>
          ) : (
            <>
              <Text fontSize="sm" mb={3}>
                Fill out what you actually worked instead:
              </Text>

              <VStack spacing={4} align="stretch">
                {lines.map((line, lineIdx) => {
                  const reasonStrings = getReasonOptionsForCombinedId(line.storeId);
                  const reasonOptions = reasonStrings.map((r) => ({ value: r, label: r }));
                  const selectedReasons = reasonOptions.filter((opt) =>
                    line.reasons.includes(opt.value)
                  );
                  const isOtherSelected = line.reasons.includes('Other');

                  return (
                    <Box
                      key={lineIdx}
                      position="relative"
                      bg="white"
                      p={4}
                      borderRadius="md"
                      boxShadow="md"
                      borderLeftWidth="4px"
                      borderLeftColor="blue.400"
                    >
                      <Text fontSize="sm" fontWeight="semibold" mb={2}>
                        Shift #{lineIdx + 1}
                      </Text>

                      {/* Hide trash can if it's the first line */}
                      {lineIdx > 0 && (
                        <IconButton
                          icon={<DeleteIcon />}
                          aria-label="Remove shift line"
                          size="sm"
                          variant="outline"
                          colorScheme="red"
                          position="absolute"
                          top="8px"
                          right="8px"
                          onClick={() => handleRemoveLine(lineIdx)}
                        />
                      )}

                      <VStack align="stretch" spacing={3} mt={1}>
                        <HStack align="flex-start" spacing={3} wrap="wrap">
                          <Box minW="200px">
                            <Text fontSize="xs" fontWeight="bold" mb={1}>
                              Store/Event
                            </Text>
                            <select
                              style={{
                                width: '100%',
                                padding: '6px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                              }}
                              value={line.storeId}
                              onChange={(e) => handleLineChange(lineIdx, 'storeId', e.target.value)}
                            >
                              <option value="">-- Select an option --</option>
                              {storeData.map((sd) => (
                                <option key={sd.store_id} value={sd.store_id}>
                                  {sd.store_name || `Store#${sd.store_id}`}
                                </option>
                              ))}
                              <option value="Vacation">Vacation</option>
                              <option value="Office">Office</option>
                              <option value="Meetings">Meetings</option>
                            </select>
                          </Box>

                          {reasonOptions.length > 0 && (
                            <Box minW="200px">
                              <Text fontSize="xs" fontWeight="bold" mb={1}>
                                Reasons
                              </Text>
                              <Select
                                isMulti
                                options={reasonOptions}
                                value={selectedReasons}
                                onChange={(vals) => handleReasonsChange(lineIdx, vals)}
                                placeholder="Select reason(s)"
                              />
                            </Box>
                          )}
                        </HStack>

                        <HStack align="flex-start" spacing={3} wrap="wrap">
                          <Box>
                            <Text fontSize="xs" fontWeight="bold" mb={1}>
                              Start
                            </Text>
                            <input
                              type="datetime-local"
                              value={toDateTimeLocalString(line.startTime)}
                              onChange={(e) => handleDateTimeChange(lineIdx, 'startTime', e)}
                              style={{
                                width: '250px',
                                minWidth: '180px',
                                padding: '6px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                              }}
                            />
                          </Box>

                          <Box>
                            <Text fontSize="xs" fontWeight="bold" mb={1}>
                              End
                            </Text>
                            <input
                              type="datetime-local"
                              value={toDateTimeLocalString(line.endTime)}
                              onChange={(e) => handleDateTimeChange(lineIdx, 'endTime', e)}
                              style={{
                                width: '250px',
                                minWidth: '180px',
                                padding: '6px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                              }}
                            />
                          </Box>
                        </HStack>

                        {isOtherSelected && (
                          <Box>
                            <Text fontSize="xs" fontWeight="bold" mb={1}>
                              Other Reason
                            </Text>
                            <input
                              type="text"
                              value={line.reasonOther}
                              onChange={(e) =>
                                handleLineChange(lineIdx, 'reasonOther', e.target.value)
                              }
                              style={{
                                width: '100%',
                                padding: '6px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                              }}
                              placeholder="Describe your custom reason..."
                            />
                          </Box>
                        )}
                      </VStack>
                    </Box>
                  );
                })}

                {/* One row for: "Please fill store..." warning + Add/Mark buttons */}
                <HStack justify="space-between" mt={2} alignItems="center" wrap="wrap">
                  {/* Warning message on the left, if needed */}
                  {!allLinesValid && lines.length > 0 ? (
                    <Text color="orange.600" fontSize="sm">
                      Please fill Store, Start & End times for each line.
                    </Text>
                  ) : (
                    <Box /> // an empty box to keep spacing if not shown
                  )}

                  <HStack spacing={3}>
                    <Button
                      size="sm"
                      leftIcon={<AddIcon />}
                      onClick={handleAddLine}
                      colorScheme="blue"
                    >
                      Add Another Shift
                    </Button>

                    {didNotWorkFlags[currentIndex] ? (
                      <Button size="sm" colorScheme="gray" onClick={handleUndoNoWork}>
                        Undo Did Not Work
                      </Button>
                    ) : (
                      <Button size="sm" colorScheme="red" onClick={handleNoWork}>
                        Mark as Not Worked
                      </Button>
                    )}
                  </HStack>
                </HStack>
              </VStack>
            </>
          )}
        </ModalBody>

        <ModalFooter borderTopWidth="1px" borderColor="gray.200">
          {/* If we are on the last shift, show Submit. Otherwise, show Next. */}
          {currentIndex === variantShifts.length - 1 ? (
            <Button
              colorScheme="blue"
              onClick={handleSubmitAll}
              // Only enable if "didNotWork" or all lines are valid
              isDisabled={!allLinesValid}
            >
              Submit Shifts
            </Button>
          ) : (
            <Button
              colorScheme="blue"
              onClick={handleNext}
              isDisabled={!allLinesValid}
            >
              Next
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
