// generatePopsReportPDFv2.js
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
// Use a namespace import so that all exported functions are available.
import * as gradeKPI from './GradeKPIsv2';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

/**
 * Helper function to convert minutes (number) to an "h:mm" string.
 * @param {number|string} minutes 
 * @returns {string}
 */
function convertMinutesToTimeStr(minutes) {
  const total = Number(minutes);
  if (isNaN(total)) return minutes;
  const hours = Math.floor(total / 60);
  const mins = Math.round(total % 60);
  return `${hours}:${mins < 10 ? '0' : ''}${mins}`;
}

/**
 * Maps grade colors (returned by gradeKPI functions) to PDF color hex codes.
 * (Ensure these hex codes work well with pdfMake.)
 */
const colorMap = {
  green: '#00FF00',    // bright green
  yellow: '#FFFF00',   // yellow
  red: '#FF0000',      // red
  default: '#FFFFFF',  // white
};

/**
 * Generates a PDF and triggers download.
 *
 * @param {Array} reportData - Array of store objects (final store ranking from the backend).
 *   Each object is expected to have keys:
 *      overallRank, storeName, mtdSales, salesPctChange, contExpenses,
 *      crewProduction, laborPct, foodVariance, onTimesPct, avgTime, replyTime,
 *      complaintsPer10K, cash.
 * @param {string} selectedMonth - e.g., "January 2025"
 * @param {string} selectedArea - e.g., "Kansas & Nebraska" (or empty string)
 * @param {object} gradingStandards - Object keyed by KPI name, for example:
 *   {
 *     salesPctChange: { greenThreshold: 5, yellowThreshold: -5, redThreshold: -5 },
 *     contExpenses: { greenThreshold: 55, yellowThreshold: null, redThreshold: 56 },
 *     crewProduction: { greenThreshold: 59, yellowThreshold: null, redThreshold: null },
 *     laborPct: { greenThreshold: 23, yellowThreshold: null, redThreshold: 25 },
 *     foodVariance: { greenThreshold: -1, yellowThreshold: null, redThreshold: -1.5 },
 *     onTimesPct: { greenThreshold: 80, yellowThreshold: null, redThreshold: 70 },
 *     avgTime: { greenThreshold: 180, yellowThreshold: null, redThreshold: 240 },
 *     replyTime: { greenThreshold: 5, yellowThreshold: null, redThreshold: 10 },
 *     complaintsPer10K: { greenThreshold: 1, yellowThreshold: null, redThreshold: 2 },
 *     cash: { greenThreshold: -50, yellowThreshold: null, redThreshold: -100 },
 *   }
 */
export const captureTableAndDownloadPDF = (reportData, selectedMonth, selectedArea = '', gradingStandards) => {
  // Build header row array for the table.
  const headerCells = [
    { text: 'Rank', style: 'tableHeader' },
    { text: 'Store', style: 'tableHeader' },
    { text: 'MTD Sales', style: 'tableHeader' },
    { text: 'Sales % Change', style: 'tableHeader' },
    { text: 'Controllables', style: 'tableHeader' },
    { text: 'Production', style: 'tableHeader' },
    { text: 'Labor %', style: 'tableHeader' },
    { text: 'Variance', style: 'tableHeader' },
    { text: 'On Times', style: 'tableHeader' },
    { text: 'Ave. Time', style: 'tableHeader' },
    { text: 'Reply Time', style: 'tableHeader' },
    { text: 'Sync', style: 'tableHeader' },
    { text: 'Cash', style: 'tableHeader' }
  ];
  
  // Build table body from reportData.
  // For each row, apply the corresponding gradeKPI function to set the fillColor.
  const bodyData = reportData.map(row => {
    // Compute grade results for each KPI.
    const salesGrade = gradeKPI.salesPctChange(row.salesPctChange, gradingStandards.salesPctChange || {});
    const contGrade = gradeKPI.contExpenses(row.contExpenses, gradingStandards.contExpenses || {});
    const crewGrade = gradeKPI.crewProduction(row.crewProduction, gradingStandards.crewProduction || {});
    const laborGrade = gradeKPI.laborPct(row.laborPct, gradingStandards.laborPct || {});
    const foodGrade = gradeKPI.foodVariance(row.foodVariance, gradingStandards.foodVariance || {});
    const onTimesGrade = gradeKPI.onTimesPct(row.onTimesPct, gradingStandards.onTimesPct || {});
    const avgTimeGrade = gradeKPI.avgTime(row.avgTime, gradingStandards.avgTime || {});
    const replyTimeGrade = gradeKPI.replyTime(row.replyTime, gradingStandards.replyTime || {});
    const syncGrade = gradeKPI.complaintsPer10K(row.complaintsPer10K, gradingStandards.complaintsPer10K || {});
    const cashGrade = gradeKPI.cash(row.cash, gradingStandards.cash || {});

    return [
      { text: row.overallRank.toString(), style: 'tableCell', fillColor: colorMap.default },
      { text: row.storeName, style: 'tableCell', fillColor: colorMap.default },
      { text: row.mtdSales, style: 'tableCell', fillColor: colorMap.default },
      { text: row.salesPctChange, style: 'tableCell', fillColor: colorMap[salesGrade.color] || colorMap.default },
      { text: row.contExpenses, style: 'tableCell', fillColor: colorMap[contGrade.color] || colorMap.default },
      { text: row.crewProduction, style: 'tableCell', fillColor: colorMap[crewGrade.color] || colorMap.default },
      { text: row.laborPct, style: 'tableCell', fillColor: colorMap[laborGrade.color] || colorMap.default },
      { text: row.foodVariance, style: 'tableCell', fillColor: colorMap[foodGrade.color] || colorMap.default },
      { text: row.onTimesPct, style: 'tableCell', fillColor: colorMap[onTimesGrade.color] || colorMap.default },
      { text: convertMinutesToTimeStr(row.avgTime), style: 'tableCell', fillColor: colorMap[avgTimeGrade.color] || colorMap.default },
      { text: convertMinutesToTimeStr(row.replyTime), style: 'tableCell', fillColor: colorMap[replyTimeGrade.color] || colorMap.default },
      { text: row.complaintsPer10K, style: 'tableCell', fillColor: colorMap[syncGrade.color] || colorMap.default },
      { text: row.cash, style: 'tableCell', fillColor: colorMap[cashGrade.color] || colorMap.default }
    ];
  });
  
  // Define column widths for the PDF table.
  const columnWidths = [
    30, // Rank
    75, // Store
    60, // MTD Sales
    60, // Sales % Change
    70, // Controllables
    60, // Production
    50, // Labor %
    50, // Variance
    50, // On Times
    60, // Ave. Time
    60, // Reply Time
    40, // Sync
    60  // Cash
  ];
  
  const docDefinition = {
    pageOrientation: 'landscape',
    content: [
      {
        text: `${selectedArea ? `${selectedArea} ` : ''}Pops Report`,
        style: 'reportTitle'
      },
      {
        text: selectedMonth,
        style: 'reportSubtitle'
      },
      { text: '', margin: [0, 0, 0, 10] },
      {
        table: {
          headerRows: 1,
          widths: columnWidths,
          body: [
            headerCells,
            ...bodyData
          ]
        },
        layout: {
          hLineWidth: function() { return 1; },
          vLineWidth: function() { return 1; },
          hLineColor: function() { return '#CCCCCC'; },
          vLineColor: function() { return '#CCCCCC'; },
          // No default fillColor here as each cell already specifies it.
        }
      }
    ],
    styles: {
      reportTitle: {
        fontSize: 16,
        bold: true,
        alignment: 'center',
        margin: [0, 0, 0, 10]
      },
      reportSubtitle: {
        fontSize: 12,
        alignment: 'center',
        margin: [0, 0, 0, 10]
      },
      tableHeader: {
        bold: true,
        fontSize: 9,
        color: 'black',
        alignment: 'center'
      },
      tableCell: {
        fontSize: 8,
        alignment: 'center'
      }
    },
    pageMargins: [40, 20, 40, 20]
  };

  pdfMake.createPdf(docDefinition).download('pops_report.pdf');
};
