// src/components/ProlifiField/Schedules/DirectorScheduleSection.jsx

import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState
} from 'react';
import {
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  TimelineViews,
  Month,
  DragAndDrop,
  Resize,
  ResourcesDirective,
  ResourceDirective,
  Inject
} from '@syncfusion/ej2-react-schedule';
import axios from 'axios';

// The two modals
import LegacyDirectorModal from './director/LegacyDirectorModal';
import DirectorCustomModal from './director/DirectorCustomModal';

import ShiftTooltip from './super/ShiftTooltip';
import { validateShift } from '../../../utils/directorShiftValidation';
import { getBaseUrl } from '../../../utils/getBaseUrl';
import SupervisorHoursOverlay from './SupervisorHoursOverlay';

/** A constant resource ID used for the Director row. */
const DIRECTOR_RESOURCE_ID = -999;

/**
 * A helper to format JS date objects as local "YYYY-MM-DD HH:mm:ss"
 */
function formatLocalDateTime(date) {
  const pad = (n) => String(n).padStart(2, '0');
  const year = date.getFullYear();
  const mon  = pad(date.getMonth() + 1);
  const day  = pad(date.getDate());
  const hr   = pad(date.getHours());
  const min  = pad(date.getMinutes());
  const sec  = pad(date.getSeconds());
  return `${year}-${mon}-${day} ${hr}:${min}:${sec}`;
}

/** A small helper to see if two Date objects fall on the same calendar day. */
function areDatesOnSameDay(d1, d2) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

/**
 * DirectorScheduleSection
 * - Renders the Syncfusion ScheduleComponent with two views:
 *   1) TimelineWeek (director row + supervisors)
 *   2) Month (director row only)
 * - Only the Director row (ID=-999) or the Director's own supervisor row are editable
 * - Includes two modals: LegacyDirectorModal (for Director events),
 *   DirectorCustomModal (for Director-as-supervisor events).
 * - Accepts a prop "directorName" for building store-visit subjects with "DirectorName - area(s)".
 */
const DirectorScheduleSection = forwardRef(function DirectorScheduleSection(props, ref) {
  const {
    events,
    setEvents,
    supervisorResource = [],
    onDateRangeChange,
    expandCollapseAndSwitchView,
    startDate,
    endDate,
    onEventsChanged,     // We'll await this so we can re-fetch from server
    directorStores,
    directorSupId,
    directorAreas,
    // The final name string for the Director, used for store-visit subjects
    directorName
  } = props;

  const scheduleRef = useRef(null);
  const overlayRef  = useRef(null);

  /**
   * originalTimesRef => used to store the event's original start/end time
   * before a drag/resize so we can revert if it crosses multiple days, etc.
   */
  const originalTimesRef = useRef({});

  // Two separate modals:
  const [isLegacyDirectorModalOpen, setIsLegacyDirectorModalOpen] = useState(false);
  const [directorRowEvent, setDirectorRowEvent] = useState(null);

  const [isSupModalOpen, setIsSupModalOpen] = useState(false);
  const [supervisorRowEvent, setSupervisorRowEvent] = useState(null);

  // For the optional hours overlay
  const [supervisorHoursMap, setSupervisorHoursMap] = useState({});
  const [nameToIdMap, setNameToIdMap] = useState({});

  // A set of supervisor IDs that have a "completed" timesheet for the displayed week
  const [completedSupIds, setCompletedSupIds] = useState(new Set());

  // Keep track of the scheduler's selected date in local state
  const [selectedDate, setSelectedDate] = useState(new Date());

  // Expose some methods to the parent via ref
  useImperativeHandle(ref, () => ({
    switchView,
    refresh() {
      scheduleRef.current?.refreshEvents();
      scheduleRef.current?.dataBind();
    }
  }));

  // --------------------------------------------------------------------------
  // (A) Function to fetch the "completed weeks" from pfield_completed_weeks table
  // --------------------------------------------------------------------------
  async function fetchCompletedWeeks(startLocal, endLocal) {
    try {
      const baseUrl = getBaseUrl();
      const url = `${baseUrl}/pfield_events/pfield_director/completedWeeks`;
      const payload = { startDate: startLocal, endDate: endLocal };
      const response = await axios.post(url, payload, { withCredentials: true });
      console.log('[fetchCompletedWeeks] =>', response.data);

      const newSet = new Set();
      if (response.data && response.data.completedWeeks) {
        response.data.completedWeeks.forEach((w) => {
          if (w.status === 'complete' && w.user_id) {
            newSet.add(w.user_id);
          }
        });
      }
      setCompletedSupIds(newSet);
    } catch (err) {
      console.error('Error fetching completed weeks =>', err);
    }
  }

  // --------------------------------------------------------------------------
  // DELETE a director event in DB
  // --------------------------------------------------------------------------
  async function deleteDirectorShift(id) {
    try {
      const baseUrl = getBaseUrl();
      const delUrl = `${baseUrl}/pfield_events/pfield_director/directorEvent/${id}`;
      await axios.delete(delUrl, { withCredentials: true });
      console.log('Deleted director event =>', id);

      // Also remove from local state
      setEvents(prev => prev.filter(e => e.Id !== id));
    } catch (err) {
      console.error('Error deleting director event =>', err);
      alert('Error deleting director event. Check console for details.');
    }
  }

  // --------------------------------------------------------------------------
  // Recompute name→id map + total hours each time events or resources change
  // --------------------------------------------------------------------------
  useEffect(() => {
    const newNameMap = {};
    supervisorResource.forEach(r => {
      if (r.id !== DIRECTOR_RESOURCE_ID) {
        newNameMap[r.text] = r.id;
      }
    });
    setNameToIdMap(newNameMap);

    // Calculate total hours per Supervisor
    const newHoursMap = {};
    events.forEach(ev => {
      if (ev.SupervisorId && ev.SupervisorId !== DIRECTOR_RESOURCE_ID) {
        const s = new Date(ev.startTime);
        const e = new Date(ev.endTime);
        if (!isNaN(s) && !isNaN(e)) {
          const hrs = (e - s) / (1000 * 60 * 60);
          newHoursMap[ev.SupervisorId] = (newHoursMap[ev.SupervisorId] || 0) + hrs;
        }
      }
    });
    setSupervisorHoursMap(newHoursMap);
  }, [events, supervisorResource]);

  // (B) On first mount => if current view is TimelineWeek, fetch completed weeks
  useEffect(() => {
    if (scheduleRef.current) {
      if (scheduleRef.current.currentView === 'TimelineWeek') {
        const vDates = scheduleRef.current.getCurrentViewDates();
        if (vDates?.length > 0) {
          const earliest = new Date(vDates[0]);
          earliest.setHours(0, 0, 0, 0);
          const latest = new Date(vDates[vDates.length - 1]);
          latest.setHours(23, 59, 59, 999);

          const earliestStr = formatLocalDateTime(earliest);
          const latestStr   = formatLocalDateTime(latest);
          fetchCompletedWeeks(earliestStr, latestStr);
        }
      }
    }
  }, []);

  // (C) If parent doesn't have start/end, set them from the schedule’s visible dates in local strings
  useEffect(() => {
    if (scheduleRef.current && (!startDate || !endDate)) {
      const vDates = scheduleRef.current.getCurrentViewDates();
      if (vDates?.length > 0) {
        const earliest = new Date(vDates[0]);
        earliest.setHours(0, 0, 0, 0);
        const latest = new Date(vDates[vDates.length - 1]);
        latest.setHours(23, 59, 59, 999);

        onDateRangeChange?.(
          formatLocalDateTime(earliest),
          formatLocalDateTime(latest)
        );
      }
    }
  }, [onDateRangeChange, startDate, endDate]);

  // --------------------------------------------------------------------------
  // Switch the schedule view (e.g. Month <-> TimelineWeek)
  // --------------------------------------------------------------------------
  function switchView(viewName) {
    if (scheduleRef.current) {
      scheduleRef.current.currentView = viewName;
      scheduleRef.current.dataBind();
    }
  }

  // --------------------------------------------------------------------------
  // Resource header template => used to show "✔" if that resource is "complete"
  // --------------------------------------------------------------------------
  function resourceHeaderTemplate(props) {
    const isTimelineWeek = (scheduleRef.current?.currentView === 'TimelineWeek');
    const isComplete = completedSupIds.has(props.resourceData.id);

    return (
      <div className="my-resource-label" style={{ display: 'flex', alignItems: 'center' }}>
        <span>{props.resourceData.text}</span>
        {isTimelineWeek && isComplete && (
          <span style={{ marginLeft: 8, color: 'green', fontWeight: 'bold' }}>✔</span>
        )}
      </div>
    );
  }

  // --------------------------------------------------------------------------
  // DOUBLE-CLICK HANDLERS => open the correct modal
  // --------------------------------------------------------------------------
  function handleCellDoubleClick(args) {
    args.cancel = true;

    // If user double-clicks a cell in Month => treat it as director shift
    if (scheduleRef.current?.currentView === 'Month') {
      const day = new Date(args.startTime);
      day.setHours(9, 0, 0, 0);
      const dayEnd = new Date(day);
      dayEnd.setHours(17, 0, 0, 0);

      const newEv = {
        Id: null,
        SupervisorId: DIRECTOR_RESOURCE_ID,
        Subject: '',
        startTime: day,
        endTime: dayEnd,
        Description: '',
        storeOrEvent: 'store-visits',
        storeIds: [],
        notes: []
      };

      setDirectorRowEvent(newEv);
      setIsLegacyDirectorModalOpen(true);
      return;
    }

    // Otherwise, for TimelineWeek => check which resource row
    const groupIndex = args.groupIndex;
    if (groupIndex == null) {
      alert('Invalid resource row => read-only');
      return;
    }
    const cellResource = scheduleRef.current.getResourcesByIndex(groupIndex);
    if (!cellResource || !cellResource.resourceData) {
      alert('Unknown resource => read-only');
      return;
    }

    const supId = cellResource.resourceData.id;

    // Default to 9am–5pm new shift
    const day = new Date(args.startTime);
    day.setHours(9, 0, 0, 0);
    const dayEnd = new Date(day);
    dayEnd.setHours(17, 0, 0, 0);

    const newEv = {
      Id: null,
      SupervisorId: supId,
      Subject: '',
      startTime: day,
      endTime: dayEnd,
      Description: '',
      storeOrEvent:'store-visits',
      storeIds: [],
      notes: []
    };

    if (supId === DIRECTOR_RESOURCE_ID) {
      // Director row => open legacy modal
      setDirectorRowEvent(newEv);
      setIsLegacyDirectorModalOpen(true);
    } else if (directorSupId && supId === directorSupId) {
      // Director is also a supervisor => open supervisor modal
      setSupervisorRowEvent(newEv);
      setIsSupModalOpen(true);
    } else {
      alert('This supervisor is read-only. No new shift allowed here.');
    }
  }

  function handleEventDoubleClick(args) {
    args.cancel = true;
    const ev = args.event || {};
    const supId = ev.SupervisorId;
    if (!supId) return;

    if (supId === DIRECTOR_RESOURCE_ID) {
      setDirectorRowEvent(ev);
      setIsLegacyDirectorModalOpen(true);
    } else if (directorSupId && supId === directorSupId) {
      setSupervisorRowEvent(ev);
      setIsSupModalOpen(true);
    } else {
      alert('Supervisor shift => read-only');
    }
  }

  // --------------------------------------------------------------------------
  // DIRECTOR MODAL -> SAVE & DELETE
  // --------------------------------------------------------------------------
  async function handleDirectorModalSave(modEv) {
    try {
      const baseUrl = getBaseUrl();
      const saveUrl = `${baseUrl}/pfield_events/pfield_director/saveDirectorEvent`;

      function localString(dateObj) {
        if (!(dateObj instanceof Date)) return '';
        return formatLocalDateTime(dateObj);
      }

      // Build subject specifically for store visits:
      let subjectVal;
      if (modEv.storeOrEvent === 'store-visits') {
        const areasJoined = modEv.storeIds.join(', ');
        subjectVal = `${directorName} - ${areasJoined}`;
      } else if (modEv.storeOrEvent?.startsWith('static-')) {
        subjectVal = modEv.storeOrEvent.substring(7); // e.g. "Office"
      } else {
        subjectVal = 'Misc';
      }

      // Prepare payload
      const payload = {
        ...modEv,
        startTime: localString(new Date(modEv.startTime)),
        endTime:   localString(new Date(modEv.endTime))
      };

      // Post to DB
      const { data } = await axios.post(saveUrl, payload, { withCredentials: true });
      console.log('Saved director event =>', data);

      let finalId = modEv.Id;
      if (data.insertedId) {
        finalId = data.insertedId;
      } else if (data.eventId) {
        finalId = data.eventId;
      }

      // Merge or add to local events
      if (finalId) {
        const newEvent = {
          Id: finalId,
          SupervisorId: DIRECTOR_RESOURCE_ID,
          Subject: subjectVal,
          startTime: modEv.startTime,
          endTime: modEv.endTime,
          Description: '',
          storeOrEvent: modEv.storeOrEvent,
          storeIds:     modEv.storeIds || [],
          notes:        modEv.notes || []
        };

        if (modEv.Id) {
          setEvents(prev => prev.map(e => e.Id === modEv.Id ? newEvent : e));
        } else {
          setEvents(prev => [...prev, newEvent]);
        }
      }

      setIsLegacyDirectorModalOpen(false);

      // Re-fetch from server, then refresh
      if (onEventsChanged) {
        await onEventsChanged();
      }
      scheduleRef.current?.refreshEvents();
      scheduleRef.current?.dataBind();
    } catch (err) {
      console.error('Error saving director event =>', err);
      alert('Error saving director event. Check console for details.');
    }
  }

  async function handleDirectorModalDelete(id) {
    try {
      const baseUrl = getBaseUrl();
      const delUrl = `${baseUrl}/pfield_events/pfield_director/directorEvent/${id}`;
      await axios.delete(delUrl, { withCredentials: true });
      console.log('Deleted director event =>', id);

      setEvents(prev => prev.filter(e => e.Id !== id));
      setIsLegacyDirectorModalOpen(false);

      // Re-fetch from server, then refresh
      if (onEventsChanged) {
        await onEventsChanged();
      }
      scheduleRef.current?.refreshEvents();
      scheduleRef.current?.dataBind();
    } catch (err) {
      console.error('Error deleting director event =>', err);
      alert('Error deleting director event. Check console for details.');
    }
  }

  // --------------------------------------------------------------------------
  // DIRECTOR’s SUPERVISOR MODAL -> SAVE & DELETE
  // --------------------------------------------------------------------------
  async function handleSupervisorModalSave(modEv) {
    // The DB update already happens in DirectorCustomModal
    // We just update local state and refresh
    if (modEv.Id) {
      setEvents(prev => prev.map(e => (e.Id === modEv.Id ? { ...e, ...modEv } : e)));
    } else {
      if (!modEv.Id) {
        modEv.Id = Math.floor(Math.random() * 900000) + 100;
      }
      setEvents(prev => [...prev, modEv]);
    }
    setIsSupModalOpen(false);

    if (onEventsChanged) {
      await onEventsChanged();
    }
    scheduleRef.current?.refreshEvents();
    scheduleRef.current?.dataBind();
  }

  async function handleSupervisorModalDelete(id) {
    // The DB delete is done in DirectorCustomModal
    setEvents(prev => prev.filter(e => e.Id !== id));
    setIsSupModalOpen(false);

    if (onEventsChanged) {
      await onEventsChanged();
    }
    scheduleRef.current?.refreshEvents();
    scheduleRef.current?.dataBind();
  }

  // --------------------------------------------------------------------------
  // DRAG & RESIZE + onActionBegin => handle drag/resize + delete
  // --------------------------------------------------------------------------
  function handleDragStart(args) {
    // Store the original times for revert if needed
    if (scheduleRef.current?.currentView === 'TimelineWeek') {
      const ev = Array.isArray(args.data) ? args.data[0] : args.data;
      if (ev?.Id) {
        if (
          ev.SupervisorId === DIRECTOR_RESOURCE_ID ||
          (directorSupId && ev.SupervisorId === directorSupId)
        ) {
          originalTimesRef.current[ev.Id] = {
            start: new Date(ev.startTime),
            end:   new Date(ev.endTime)
          };
        }
      }
    }
  }

  function handleResizeStart(args) {
    handleDragStart(args);
  }

  async function deleteSupervisorShiftInDb(shiftId) {
    try {
      const baseUrl = getBaseUrl();
      const delUrl = `${baseUrl}/pfield_events/pfield_director/supervisorEvent/${shiftId}`;
      await axios.delete(delUrl, { withCredentials: true });
      console.log('Supervisor shift deleted =>', shiftId);

      setEvents(prev => prev.filter(e => e.Id !== shiftId));
    } catch (err) {
      console.error('Error deleting supervisor shift =>', err);
      alert('Error deleting supervisor shift. Check console for details.');
    }
  }

  async function saveDirectorSupShiftViaDrag(ev) {
    try {
      const baseUrl = getBaseUrl();
      const url = `${baseUrl}/pfield_events/pfield_director/supervisorEvent`;

      const payload = {
        Id: ev.Id,
        startTime: formatLocalDateTime(new Date(ev.startTime)),
        endTime:   formatLocalDateTime(new Date(ev.endTime)),
        Subject:   ev.Subject || '',
        storeOrEvent: ev.storeOrEvent || '',
        storeIds:  ev.storeIds || [],
        notes:     ev.notes || []
      };

      const { data } = await axios.post(url, payload, { withCredentials: true });
      console.log('Supervisor shift updated =>', data);

      setEvents(prev => prev.map(e => {
        if (e.Id === ev.Id) {
          return { ...e, startTime: ev.startTime, endTime: ev.endTime };
        }
        return e;
      }));
    } catch (err) {
      console.error('Error updating supervisor shift =>', err);
      alert('Error updating supervisor shift. Reverting...');
    }
  }

async function saveDirectorShiftViaDrag(ev) {
  try {
    // Look up the current event data from state
    const currentEvent = events.find(e => e.Id === ev.Id) || {};

    // Determine storeOrEvent value (drag value or fallback)
    const storeOrEvent = ev.storeOrEvent || currentEvent.storeOrEvent || 'static-Office';

    // Determine storeIds:
    // 1. Use ev.storeIds if available and non-empty.
    // 2. Else fallback to currentEvent.storeIds if available.
    // 3. If still empty and storeOrEvent is "store-visits", parse the subject string.
    let storeIds = [];
    if (ev.storeIds && ev.storeIds.length > 0) {
      storeIds = ev.storeIds;
    } else if (currentEvent.storeIds && currentEvent.storeIds.length > 0) {
      storeIds = currentEvent.storeIds;
    } else if (storeOrEvent === 'store-visits') {
      // Attempt to parse store names from the subject.
      // The subject is expected to be in the format: "{director name} - {list of stores}"
      const subj = ev.Subject || currentEvent.Subject || "";
      const dashIndex = subj.indexOf("-");
      if (dashIndex !== -1) {
        const areasPart = subj.substring(dashIndex + 1).trim();
        // Split by comma, trim each, and filter out any empty strings.
        storeIds = areasPart.split(",").map(s => s.trim()).filter(s => s);
      }
    }

    // Determine notes using fallback logic
    const notes = (ev.notes !== undefined ? ev.notes : currentEvent.notes) || [];

    // Build the payload for the update
    const payload = {
      Id: ev.Id,
      startTime: formatLocalDateTime(new Date(ev.startTime)),
      endTime:   formatLocalDateTime(new Date(ev.endTime)),
      storeOrEvent,
      storeIds,
      notes
    };

    const baseUrl = getBaseUrl();
    const url = `${baseUrl}/pfield_events/pfield_director/saveDirectorEvent`;

    const { data } = await axios.post(url, payload, { withCredentials: true });
    console.log('Director shift updated after drag =>', data);

    // Update the event in state, ensuring custom fields are preserved
    setEvents(prev => prev.map(e => {
      if (e.Id === ev.Id) {
        return {
          ...e,
          startTime: ev.startTime,
          endTime: ev.endTime,
          storeOrEvent,
          storeIds,
          notes
        };
      }
      return e;
    }));
  } catch (err) {
    console.error('Error updating director shift =>', err);
    alert('Error updating director shift. Reverting...');
  }
}


  async function onActionBegin(args) {
    const rt = args.requestType;

    // If it's a create/change/remove event, check if it's allowed
    if (['eventCreate','eventChange','eventRemove'].includes(rt)) {
      const changedList = Array.isArray(args.data) ? args.data : [args.data];
      for (const ev of changedList) {
        // If shift belongs to a resource we do NOT allow editing => cancel
        if (
          ev.SupervisorId !== DIRECTOR_RESOURCE_ID &&
          ev.SupervisorId !== directorSupId
        ) {
          args.cancel = true;
          return;
        }
      }

      if (rt === 'eventCreate' || rt === 'eventChange') {
        const { cancel, message } = validateShift(args, events, scheduleRef);
        if (cancel) {
          args.cancel = true;
          if (message) alert(message);
          return;
        }
      }
    }

    if (rt === 'eventChange') {
      // We manually handle the changes => cancel the default
      args.cancel = true;
      const changedList = Array.isArray(args.data) ? args.data : [args.data];

      for (let ev of changedList) {
		if (scheduleRef.current?.currentView === 'TimelineWeek') {
		  const orig = originalTimesRef.current[ev.Id];
		  if (orig) {
			const newStart = new Date(ev.startTime);
			const newEnd   = new Date(ev.endTime);
			// For non-director events, if the shift crosses multiple days, revert the change.
			// For director events (SupervisorId === DIRECTOR_RESOURCE_ID), allow multi-day shifts.
			if (ev.SupervisorId !== DIRECTOR_RESOURCE_ID && !areDatesOnSameDay(newStart, newEnd)) {
			  ev.startTime = new Date(orig.start);
			  ev.endTime   = new Date(orig.end);
			}
		  }
		}


        // Now we update in DB (Director or Director-as-supervisor)
        if (ev.SupervisorId === DIRECTOR_RESOURCE_ID) {
          await saveDirectorShiftViaDrag(ev);
        } else if (directorSupId && ev.SupervisorId === directorSupId) {
          await saveDirectorSupShiftViaDrag(ev);
        }
      }

      // Re-fetch from server, then refresh
      if (onEventsChanged) {
        await onEventsChanged();
      }
      scheduleRef.current?.refreshEvents();
      scheduleRef.current?.dataBind();
    }

    if (rt === 'eventRemove') {
      args.cancel = true;
      const removedList = Array.isArray(args.data) ? args.data : [args.data];
      for (let ev of removedList) {
        if (ev.SupervisorId === DIRECTOR_RESOURCE_ID) {
          await deleteDirectorShift(ev.Id);
        } else if (directorSupId && ev.SupervisorId === directorSupId) {
          await deleteSupervisorShiftInDb(ev.Id);
        }
      }

      if (onEventsChanged) {
        await onEventsChanged();
      }
      scheduleRef.current?.refreshEvents();
      scheduleRef.current?.dataBind();
    }
  }

  // --------------------------------------------------------------------------
  // onActionComplete => after the view or date changes
  // --------------------------------------------------------------------------
  async function onActionComplete(args) {
    if (args.requestType === 'viewNavigate' || args.requestType === 'dateNavigate') {
      setTimeout(async () => {
        if (scheduleRef.current) {
          if (scheduleRef.current.currentView === 'Month') {
            // If Month => override the date range to the actual calendar month
            const sel = new Date(scheduleRef.current.selectedDate);
            const startOfMonth = new Date(sel.getFullYear(), sel.getMonth(), 1, 0, 0, 0, 0);
            const endOfMonth   = new Date(sel.getFullYear(), sel.getMonth() + 1, 0, 23, 59, 59, 999);

            onDateRangeChange?.(
              formatLocalDateTime(startOfMonth),
              formatLocalDateTime(endOfMonth)
            );
          } else if (scheduleRef.current.currentView === 'TimelineWeek') {
            // If TimelineWeek => use getCurrentViewDates to set parent date range
            const vDates = scheduleRef.current.getCurrentViewDates();
            if (vDates?.length > 0) {
              const earliest = new Date(vDates[0]);
              earliest.setHours(0, 0, 0, 0);
              const latest = new Date(vDates[vDates.length - 1]);
              latest.setHours(23, 59, 59, 999);

              onDateRangeChange?.(
                formatLocalDateTime(earliest),
                formatLocalDateTime(latest)
              );

              // Also fetch completed weeks for the new range
              await fetchCompletedWeeks(
                formatLocalDateTime(earliest),
                formatLocalDateTime(latest)
              );
            }
          }

          // Update local selectedDate to keep the schedule from resetting
          setSelectedDate(scheduleRef.current.selectedDate);
        }
      }, 0);
    }
  }

  function onDataBound() {
    // If we have a SupervisorHoursOverlay, recalc positions:
    if (overlayRef.current?.recalcPositions) {
      overlayRef.current.recalcPositions();
    }
  }

  function onPopupOpen(args) {
    // We always use custom modals => cancel default Syncfusion popups
    if (args.type === 'Editor' || args.type === 'QuickInfo') {
      args.cancel = true;
    }
  }

  // --------------------------------------------------------------------------
  // eventRendered => style the event element
  // --------------------------------------------------------------------------
  function onEventRendered(args) {
    if (
      args.data.SupervisorId &&
      args.data.SupervisorId !== DIRECTOR_RESOURCE_ID
    ) {
      // Supervisor row => color from resource
      const found = supervisorResource.find(r => r.id === args.data.SupervisorId);
      if (found) {
        args.element.style.backgroundColor = found.color;
      }
      args.element.classList.remove('director-event');
    } else if (args.data.SupervisorId === DIRECTOR_RESOURCE_ID) {
      // Director row => highlight
      args.element.classList.add('director-event');
    }
  }

  // A custom template for event rendering => show times
  function shiftDurationTemplate(props) {
    const sDate = new Date(props.startTime);
    const eDate = new Date(props.endTime);
    if (isNaN(sDate) || isNaN(eDate)) {
      return (
        <div style={{ padding:'2px' }}>
          <div style={{ fontWeight:'bold' }}>{props.Subject}</div>
          <div>(Invalid date)</div>
        </div>
      );
    }
    const startStr = sDate.toLocaleTimeString([], { hour:'2-digit', minute:'2-digit' });
    const endStr   = eDate.toLocaleTimeString([], { hour:'2-digit', minute:'2-digit' });
    return (
      <div style={{ padding:'2px' }}>
        <div style={{ fontWeight:'bold' }}>{props.Subject}</div>
        <div>{startStr} - {endStr}</div>
      </div>
    );
  }

  // --------------------------------------------------------------------------
  // Render
  // --------------------------------------------------------------------------
  return (
    <div style={{ position:'relative', display:'block', maxHeight:'none' }}>
      <LegacyDirectorModal
        isOpen={isLegacyDirectorModalOpen}
        initialEvent={directorRowEvent}
        onClose={() => setIsLegacyDirectorModalOpen(false)}
        onSave={handleDirectorModalSave}
        onDelete={handleDirectorModalDelete}
        availableStores={directorAreas}
      />

      <DirectorCustomModal
        isOpen={isSupModalOpen}
        eventData={supervisorRowEvent}
        onClose={() => setIsSupModalOpen(false)}
        onSave={handleSupervisorModalSave}
        onDelete={handleSupervisorModalDelete}
        directorStores={directorStores}
      />

      <ScheduleComponent
        ref={scheduleRef}
        rowAutoHeight={true}
        showQuickInfo={true}
        dragStart={handleDragStart}
        resizeStart={handleResizeStart}
        cellDoubleClick={handleCellDoubleClick}
        eventDoubleClick={handleEventDoubleClick}
        actionBegin={onActionBegin}
        actionComplete={onActionComplete}
        popupOpen={onPopupOpen}
        eventRendered={onEventRendered}
        dataBound={onDataBound}
        selectedDate={selectedDate}
        firstDayOfWeek={1}
        currentView="Month"
        height="auto"
        width="100%"
        resourceHeaderTemplate={resourceHeaderTemplate}
		eventSettings={{
		  dataSource: events,
		  template: shiftDurationTemplate,
		  fields: {
			id: 'Id',
			subject: { name: 'Subject' },
			groupID: { name: 'SupervisorId' },
			startTime: { name: 'startTime' },
			endTime: { name: 'endTime' },
			description: { name: 'Description' },
			// Add the custom fields below:
			storeIds: { name: 'storeIds' },
			storeOrEvent: { name: 'storeOrEvent' },
			notes: { name: 'notes' }
		  }
		}}
      >
        <ResourcesDirective>
          <ResourceDirective
            field="SupervisorId"
            title="Supervisor / Director"
            name="Supervisor"
            dataSource={supervisorResource}
            textField="text"
            idField="id"
            colorField="color"
            allowMultiple={false}
            expanded={true}
          />
        </ResourcesDirective>

        <ViewsDirective>
          <ViewDirective
            option="TimelineWeek"
            startHour="00:00"
            endHour="24:00"
            timeScale={{ enable:false }}
            group={{ resources:['Supervisor'] }}
            eventSettings={{
              enableTooltip:false,
              tooltipTemplate:ShiftTooltip
            }}
          />
          <ViewDirective
            option="Month"
            rowAutoHeight={true}
            group={{ resources:[] }}
            eventSettings={{
              enableTooltip:true,
              tooltipTemplate:ShiftTooltip
            }}
          />
        </ViewsDirective>
        <Inject services={[TimelineViews, Month, DragAndDrop, Resize]} />
      </ScheduleComponent>

      <SupervisorHoursOverlay
        ref={overlayRef}
        supervisorHoursMap={supervisorHoursMap}
        nameToIdMap={nameToIdMap}
      />
    </div>
  );
});

export default DirectorScheduleSection;
