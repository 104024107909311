// src/App.js

import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// Layout + components
import BaseLayout from "./components/BaseLayout";
import Login from "./components/Login";
import PrFileUpload from "./components/prFileUpload";
import SScheduleAnalyzer from "./components/SScheduleAnalyzer";
import PdfReview from "./components/PdfReview";
import ResetPassword from "./components/ResetPassword";
import NotFound from "./components/NotFound";
import BbUpload from "./components/bbUpload";
import UserCreationForm from "./components/UserCreationForm";
import WManual from "./components/W-Manual";
import WeeklySalesReport from "./components/WeeklySalesReport";
import axios from "axios";
import LoadingIndicator from "./LoadingIndicator";
import PopsReport from "./components/PopsReport";
import PopsReportv2 from "./components/POPSv2/PopsReportv2";
import ExcelProcessor from "./components/EEDataUpload";
import TZFileUpload from "./components/TZFileUpload";
import GradingStandardsEditor from "./components/GradingStandardsEditor";
import FraudPrevention from "./components/FraudPrevention";
import StoreDashboard from "./components/storeLevel/StoreDashboard";
import MonthlyProcessing from "./components/Monthly/MonthlyProcessing";
import MonthlyManual from "./components/Monthly/MonthlyManual";
import BusinessPage from "./components/biz/BusinessPage";
import Blog from "./components/biz/Blog";
import SalesForecaster from "./components/SalesForecaster";
import StickerComponent from "./components/StickerComponent";
import ForecastDisplay from "./components/ForecastDisplay";
import StoreUserManagement from "./components/StoreUserManagement";
import CorrectiveActionDashboard from "./components/CorrectiveActionDashboard";
import VisionDental from "./components/VisionDental";
import SessionChecker from "./components/SessionChecker";
import PLReview from "./components/PLReview";
import ExcelUploader from "./components/ExcelUploader";
import EmployeeAnalyzer from "./components/EmployeeAnalyzer";
import EmployeeDataViewer from "./components/EeAffinity.js";
import ClosingCheckListBuilder from "./components/ClosingCheckListBuilder";
import CSVConverter from "./components/TruckConverter";
import PrivacyPolicy from "./components/PrivacyPolicy";
import DirectDepositChange from "./components/DirectDepositChange";
import TZFileUploadV2 from "./components/TZFileUploadV2";
import TZv2Stores from "./components/TZv2Stores";
import ProlifiFieldLayout from "./components/ProlifiField/ProlifiFieldLayout";
import Schedule from "./components/ProlifiField/Schedules/Schedule";
import WeeklyStoreNotes from "./components/ProlifiField/wsn/super/WeeklyStoreNotes";
import DirectorSchedulerContainer from "./components/ProlifiField/Schedules/DirectorSchedulerContainer";
import Cr80CardPrinter from "./components/Cr80CardPrinter";



// Authenticated Routes (replaces old MainContent approach)
function AuthenticatedRoutes({ userGroup, company, username, userStore }) {
  return (
    <Routes>
      <Route index element={<div>Dashboard</div>} />

      {/* All the routes you previously had in MainContent */}
      <Route path="prFileUpload" element={<PrFileUpload />} />
      <Route path="sScheduleAnalyzer" element={<SScheduleAnalyzer />} />
      <Route path="pdfReview" element={<PdfReview />} />
      <Route path="bbUpload" element={<BbUpload />} />
      <Route path="create-user" element={<UserCreationForm />} />
      <Route path="w-manual" element={<WManual />} />
      <Route path="weekly-sales-report" element={<WeeklySalesReport />} />
      <Route path="pops-report" element={<PopsReport />} />
	  <Route path="pops-report-v2" element={<PopsReportv2 />} />
      <Route path="ee-data-upload" element={<ExcelProcessor />} />
      <Route path="tz-file-upload" element={<TZFileUploadV2 />} />
      <Route path="fraud-prevention" element={<FraudPrevention />} />
      <Route path="monthly-processing" element={<MonthlyProcessing />} />
      <Route path="monthly-manual" element={<MonthlyManual />} />
      <Route path="grading-standards" element={<GradingStandardsEditor />} />
      <Route path="sales-forecaster" element={<SalesForecaster />} />
      <Route path="sticker-component" element={<StickerComponent />} />
      <Route path="forecast-display" element={<ForecastDisplay />} />
      <Route path="store-user-management" element={<StoreUserManagement />} />
      <Route
        path="corrective-action-dashboard"
        element={<CorrectiveActionDashboard />}
      />
      <Route path="vision-dental" element={<VisionDental />} />
      <Route path="session-checker" element={<SessionChecker />} />
      <Route path="pl-review" element={<PLReview />} />
      <Route path="shift-analyze" element={<ExcelUploader />} />
      <Route path="employee-analyzer" element={<EmployeeAnalyzer />} />
      <Route path="employee-data-viewer" element={<EmployeeDataViewer />} />
      <Route
        path="closing-checklist-builder"
        element={<ClosingCheckListBuilder />}
      />
      <Route path="/csv-converter" element={<CSVConverter />} />
      <Route path="/direct-deposit-change" element={<DirectDepositChange />} />
      <Route path="tz-file-upload-v2" element={<TZFileUploadV2 />} />
	  <Route path="cr80-card-printer" element={<Cr80CardPrinter />} />
      <Route
        path="tzv2-stores"
        element={
          <TZv2Stores
            userGroup={userGroup}
            company={company}
            username={username}
            userStore={userStore}
          />
        }
      />
	  

			  {/* 1) The main layout for ProlifiField (the mini-nav) */}
			  <Route
				path="prolifi-schedules"
				element={
				  <ProlifiFieldLayout
					userGroup={userGroup}
					company={company}
					username={username}
					userStore={userStore}
				  />
				}
			  >
				<Route path="schedule" element={<Schedule />} />
				<Route path="director-schedule" element={<DirectorSchedulerContainer />} />
				<Route path="weekly-store-notes" element={<WeeklyStoreNotes />} />
			  </Route>

			</Routes>
		  );
		}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const [userGroup, setUserGroup] = useState("");
  const [company, setCompany] = useState("");
  const [username, setUsername] = useState("");
  const [userStore, setUserStore] = useState("");

  const getBaseUrl = () => {
    const hostname = window.location.hostname;
    if (hostname === "72.167.34.236") {
      return "http://72.167.34.236:5000";
    } else if (/^(www\.)?prolifi\.app$/.test(hostname)) {
      return "https://prolifi.app";
    } else {
      return "";
    }
  };

  const checkAuthStatus = async () => {
    const baseUrl = getBaseUrl();
    try {
      const response = await axios.get(`${baseUrl}/api/auth/session-status`, {
        withCredentials: true,
      });
      if (response.data.isAuthenticated) {
        setIsAuthenticated(true);

        // Fetch user details
        const userResponse = await axios.get(`${baseUrl}/api/user-store`, {
          withCredentials: true,
        });
        setUserGroup(userResponse.data.userGroup);
        setCompany(userResponse.data.company);
        setUsername(userResponse.data.username);
        setUserStore(userResponse.data.userStore);
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error("Error checking authentication status:", error);
      setIsAuthenticated(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    // If user isn't on /login, check auth status automatically
    if (location.pathname !== "/login") {
      checkAuthStatus();
    } else {
      // If on /login, don't check (so we don't break the login flow)
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [location]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingIndicator />
      </div>
    );
  }

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
  };

  return (
    <div style={{ height: "100vh" }}>
      <Routes>
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route
          path="/login"
          element={<Login onLoginSuccess={handleLoginSuccess} />}
        />
        <Route path="/reset-password" element={<ResetPassword />} />

        {/* Directly accessible store dashboard route */}
        <Route path="/store/*" element={<StoreDashboard />} />
		
		

        {/* The new Business Page route */}
        <Route path="/business" element={<BusinessPage />} />
        <Route path="/blog" element={<Blog />} />

        {/* The BaseLayout route */}
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <BaseLayout
                username={username}
                userGroup={userGroup}
                company={company}
                userStore={userStore}
              />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        >
          {isAuthenticated && (
            <Route
              path="/*"
              element={
                <AuthenticatedRoutes
                  userGroup={userGroup}
                  company={company}
                  username={username}
                  userStore={userStore}
                />
              }
            />
          )}
        </Route>

        {/* 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
