// File: src/components/ProlifiField/Schedules/super/ProlifiFieldMiniNav.jsx

import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { FiCalendar, FiClipboard } from "react-icons/fi";
import {
  Flex,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";

function ProlifiFieldMiniNav({ onExpand, userGroup }) {
  const bgColor = "blue.50";

  const handleMouseEnter = () => onExpand(true);
  const handleMouseLeave = () => onExpand(false);

  const role = userGroup.userGroup;

  // Decide the route path based on userGroup for SCHEDULE
  const scheduleRoute =
    role === "director"
      ? "/prolifi-schedules/director-schedule"
      : "/prolifi-schedules/schedule";

  // We’ll define a new route for Weekly Store Notes
  const notesRoute = "/prolifi-schedules/weekly-store-notes";

  return (
    <Flex
      position="relative"
      alignItems="center"
      justifyContent="center"
      bg={bgColor}
      width="100%"
      boxShadow="md"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      _before={{
        content: '""',
        position: "absolute",
        left: 0,
        top: 0,
        bottom: 0,
        width: "50px",
        background: `linear-gradient(to right, transparent 0%, ${bgColor} 100%)`,
        zIndex: 1,
      }}
      _after={{
        content: '""',
        position: "absolute",
        right: 0,
        top: 0,
        bottom: 0,
        width: "50px",
        background: `linear-gradient(to left, transparent 0%, ${bgColor} 100%)`,
        zIndex: 1,
      }}
    >
      <Flex zIndex={2} gap={4} justifyContent="center" alignItems="center">
        {/* SCHEDULE Icon */}
        <Tooltip label="Schedule" aria-label="Schedule tooltip">
          <IconButton
            as={RouterLink}
            to={scheduleRoute}
            icon={<FiCalendar />}
            variant="ghost"
            size="sm"
            aria-label="Schedule"
          />
        </Tooltip>

        {/* WEEKLY NOTES Icon */}
        <Tooltip label="Weekly Notes" aria-label="Weekly Notes tooltip">
          <IconButton
            as={RouterLink}
            to={notesRoute}
            icon={<FiClipboard />}
            variant="ghost"
            size="sm"
            aria-label="Weekly Notes"
          />
        </Tooltip>
      </Flex>
    </Flex>
  );
}

export default ProlifiFieldMiniNav;
