// File: src/components/ProlifiField/Schedules/wsn/super/WeeklyStoreNotes.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getBaseUrl } from '../../../../utils/getBaseUrl';
import { Box, Text, Button, useDisclosure } from '@chakra-ui/react';
import WeeklyStoreNotesModal from './WeeklyStoreNotesModal';

export default function WeeklyStoreNotes() {
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);

  // Chakra's hook for controlling a Modal
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    async function loadStores() {
      try {
        setLoading(true);
        const url = `${getBaseUrl()}/pfield_wsn/stores`;
        const res = await axios.get(url, { withCredentials: true });
        setStores(res.data || []);
      } catch (err) {
        console.error('Error fetching WSN stores =>', err);
      } finally {
        setLoading(false);
      }
    }
    loadStores();
  }, []);

  return (
    <Box p="1rem">
      <Text fontSize="2xl" mb={3} fontWeight="bold">
        Weekly Store Notes
      </Text>

      {loading && <Text>Loading your stores...</Text>}

      {!loading && stores.length === 0 && (
        <Text>No stores found or not authorized.</Text>
      )}

      {!loading && stores.length > 0 && (
        <Box>
          <Text mb={2}>You manage {stores.length} stores.</Text>
          {/* Button to open the notes modal */}
          <Button colorScheme="blue" onClick={onOpen}>
            Open Weekly Notes (Modal)
          </Button>
        </Box>
      )}

      {/* Optional: also list them inline, as before */}
      {!loading && stores.length > 0 && (
        <ul style={{ marginTop: '1rem' }}>
          {stores.map((st) => (
            <li key={st.store_id}>
              #{st.store_id} – {st.store_name || 'Unknown'}
            </li>
          ))}
        </ul>
      )}

      {/* The separate modal component, receiving stores + open/close */}
      <WeeklyStoreNotesModal
        isOpen={isOpen}
        onClose={onClose}
        stores={stores}
      />
    </Box>
  );
}
