// ProlifiFieldLayout.jsx
import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import ProlifiFieldMiniNav from "./ProlifiFieldMiniNav";

function ProlifiFieldLayout({ userGroup }) {
  // Start with the nav expanded
  const [navExpanded, setNavExpanded] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  // Determine the user's role in a case-insensitive way.
  // If userGroup is an object with a userGroup property, use that.
  // If it's a string, you can use it directly.
  const role =
    typeof userGroup === "string"
      ? userGroup.toLowerCase()
      : userGroup.userGroup.toLowerCase();

  // Set the default schedule route based on the role.
  const scheduleRoute =
    role === "director"
      ? "/prolifi-schedules/director-schedule"
      : "/prolifi-schedules/schedule";

  // On mount, if the current path is the layout's base path, redirect to the appropriate schedule route.
  useEffect(() => {
    if (
      location.pathname === "/prolifi-schedules" ||
      location.pathname === "/prolifi-schedules/"
    ) {
      navigate(scheduleRoute, { replace: true });
    }
  }, [location.pathname, navigate, scheduleRoute]);

  // Collapse the nav after 1.5 seconds on component mount
  useEffect(() => {
    const timer = setTimeout(() => {
      setNavExpanded(false);
    }, 1500);

    // Clean up the timer on unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <Flex direction="column" position="relative" width="99vw" bg="white">
      {/* The mini nav is absolutely positioned above the main content. */}
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height={navExpanded ? "30px" : "3px"}
        transition="height 0.2s ease-in-out"
        overflow="hidden"
        zIndex={10} // <-- nav is on top
      >
        <ProlifiFieldMiniNav onExpand={setNavExpanded} userGroup={userGroup} />
      </Box>

      {/* Main content behind the mini nav.
          Give it a lower z-index so it sits beneath the nav. */}
      <Box flex="1" position="relative" zIndex={1} ml="10px">
        <Outlet />
      </Box>
    </Flex>
  );
}

export default ProlifiFieldLayout;
