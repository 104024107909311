// File: src/components/ProlifiField/Schedules/Schedule.js
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

import DashboardSection from './super/DashboardSection';
import ScheduleSection from './super/ScheduleSection';
import './super/MyScheduler.css';
import { getBaseUrl } from '../../../utils/getBaseUrl';

import ChakraConfirmModal from './super/ChakraConfirmModal';
import ChakraVariantModal from './super/ChakraVariantModal';

import {
  mergeStoresIntoWeekly,
  mergeStoresIntoMonthly,
} from '../../../utils/storeMerging';

/**
 * Helper => format a JS Date as local "YYYY-MM-DD HH:mm:ss"
 */
function formatLocalDateTime(date) {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const dd = String(date.getDate()).padStart(2, '0');
  const hh = String(date.getHours()).padStart(2, '0');
  const min = String(date.getMinutes()).padStart(2, '0');
  const ss = String(date.getSeconds()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
}

/**
 * Helper: compute the Monday–Sunday range containing a given date (string).
 */
function computeMondaySunday(dateStr) {
  const dt = new Date(dateStr);
  const dayOfWeek = dt.getDay(); // 0=Sun..6=Sat
  const shiftFromMonday = (dayOfWeek + 6) % 7;
  const monday = new Date(dt);
  monday.setDate(monday.getDate() - shiftFromMonday);
  monday.setHours(0, 0, 0, 0);

  const sunday = new Date(monday);
  sunday.setDate(sunday.getDate() + 6);
  sunday.setHours(23, 59, 59, 999);

  return {
    mondayStr: formatLocalDateTime(monday),
    sundayStr: formatLocalDateTime(sunday),
  };
}

/**
 * Helper: first Monday–Sunday of a given month (monthIndex=0..11).
 */
function computeFirstWeekOfMonth(year, monthIndex) {
  const firstOfMonth = new Date(year, monthIndex, 1, 0, 0, 0, 0);
  return computeMondaySunday(firstOfMonth.toISOString());
}

/**
 * Helper: last Monday–Sunday of a given month (monthIndex=0..11).
 */
function computeLastWeekOfMonth(year, monthIndex) {
  // new Date(year, monthIndex+1, 0) => the last day of that month
  const lastOfMonth = new Date(year, monthIndex + 1, 0, 23, 59, 59, 999);
  return computeMondaySunday(lastOfMonth.toISOString());
}

export default function MySchedulerContainer() {
  const [dashboardCollapsed, setDashboardCollapsed] = useState(false);
  const [currentView, setCurrentView] = useState('Month');

  /**
   * We'll store a "monthFocusDate" so that if user navigates to a new month in Month view,
   * we update the monthly requirements query to that new month instead of the system's "now."
   */
  const [monthFocusDate, setMonthFocusDate] = useState(new Date());

  // 1) aggregator => default Monday–Sunday
  const today = new Date();
  const dayOfWeek = today.getDay();
  const shiftFromMonday = (dayOfWeek + 6) % 7;
  const defaultMon = new Date(today);
  defaultMon.setDate(defaultMon.getDate() - shiftFromMonday);
  defaultMon.setHours(0, 0, 0, 0);

  const defaultSun = new Date(defaultMon);
  defaultSun.setDate(defaultSun.getDate() + 6);
  defaultSun.setHours(23, 59, 59, 999);

  // aggregator weekly range
  const [weeklyRangeStart, setWeeklyRangeStart] = useState(formatLocalDateTime(defaultMon));
  const [weeklyRangeEnd,   setWeeklyRangeEnd]   = useState(formatLocalDateTime(defaultSun));

  // If not Month => schedule uses that Monday–Sunday
  let initialStart = null;
  let initialEnd   = null;
  if (currentView !== 'Month') {
    initialStart = weeklyRangeStart;
    initialEnd   = weeklyRangeEnd;
  }
  const [startDate, setStartDate] = useState(initialStart);
  const [endDate,   setEndDate]   = useState(initialEnd);

  // Data sets
  const [events, setEvents] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [shiftSuggestions, setShiftSuggestions] = useState({});

  // Confirmation modals
  const [unconfirmedShifts, setUnconfirmedShifts] = useState([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [variantShifts, setVariantShifts] = useState([]);
  const [isVariantModalOpen, setIsVariantModalOpen] = useState(false);

  // Dashboard info
  const [cycleLength, setCycleLength] = useState(3);
  const [patternData, setPatternData] = useState({ patternsMap: {}, warnings: [] });
  const [dashboardData, setDashboardData] = useState({ weekly: [], monthly: [] });

  const scheduleSectionRef = useRef(null);

  // ─────────────────────────────────────────────────────────────
  // (A) Load STORES
  // ─────────────────────────────────────────────────────────────
  useEffect(() => {
    async function fetchStores() {
      try {
        const url = `${getBaseUrl()}/supervised-storesWithStoreName`;
        const res = await axios.get(url, { withCredentials: true });
        setStoreData(Array.isArray(res.data) ? res.data : []);
      } catch (err) {
        console.error('Error fetching store data =>', err);
        setStoreData([]);
      }
    }
    fetchStores();
  }, []);

  // ─────────────────────────────────────────────────────────────
  // (B) SHIFT SUGGESTIONS
  // ─────────────────────────────────────────────────────────────
  useEffect(() => {
    async function fetchSuggestions() {
      try {
        const baseUrl = getBaseUrl();
        const url = `${baseUrl}/pfield_events/shiftSuggestions`;
        const res = await axios.get(url, { withCredentials: true });
        if (res.data && res.data.suggestions) {
          setShiftSuggestions(res.data.suggestions);
        } else {
          setShiftSuggestions({});
        }
      } catch (err) {
        console.error('Error fetching shift suggestions =>', err);
        setShiftSuggestions({});
      }
    }
    fetchSuggestions();
  }, []);

  // ─────────────────────────────────────────────────────────────
  // (C) UNCONFIRMED SHIFTS
  // ─────────────────────────────────────────────────────────────
  useEffect(() => {
    async function fetchUnconfirmedShifts() {
      try {
        if (!storeData.length) return;
        const storeIds = storeData.map(s => s.store_id);
        const url = `${getBaseUrl()}/pfield_events/unconfirmed`;
        const res = await axios.get(url, {
          withCredentials: true,
          params: { storeIds },
        });
        if (Array.isArray(res.data) && res.data.length > 0) {
          setUnconfirmedShifts(res.data);
          setIsConfirmModalOpen(true);
        }
      } catch (err) {
        console.error('Error fetching unconfirmed shifts =>', err);
      }
    }
    fetchUnconfirmedShifts();
  }, [storeData]);
  
  // Whenever unconfirmedShifts changes...
useEffect(() => {
  if (unconfirmedShifts.length === 0) {
    setIsConfirmModalOpen(false); // forcibly close the confirm modal
  }
}, [unconfirmedShifts]);

// Similarly for variant shifts:
useEffect(() => {
  if (variantShifts.length === 0) {
    setIsVariantModalOpen(false); // forcibly close the variant modal
  }
}, [variantShifts]);

  async function handleConfirmModalSave(confirmedIds) {
    if (!confirmedIds || confirmedIds.length === 0) return;
    try {
      const url = `${getBaseUrl()}/pfield_events/confirm`;
      await axios.post(url, { confirmedIds, variantIds: [] }, { withCredentials: true });
      setUnconfirmedShifts(prev => prev.filter(sh => !confirmedIds.includes(sh.id)));
    } catch (err) {
      console.error('Error confirming shifts =>', err);
    }
  }

  function handleVariantNeeded(variantIds) {
    if (!variantIds?.length) return;
    const filtered = unconfirmedShifts.filter(sh => variantIds.includes(sh.id));
    setVariantShifts(filtered);
    setIsVariantModalOpen(true);
  }

async function handleVariantComplete(shiftDataArray) {
  try {
    // Separate items
    const didNotWorkItems = shiftDataArray.filter(s => s.didNotWork);
    const normalVariantItems = shiftDataArray.filter(s => !s.didNotWork);

    // 1) For each didNotWork => call /noShift
    for (const item of didNotWorkItems) {
      await axios.post(`${getBaseUrl()}/pfield_events/noShift`, { id: item.id }, { withCredentials: true });
    }

    // 2) For the normal variant items => call /variantWork
    if (normalVariantItems.length > 0) {
      await axios.post(`${getBaseUrl()}/pfield_events/variantWork`, 
        { shiftData: normalVariantItems }, 
        { withCredentials: true }
      );
    }

    // Remove from unconfirmed, etc.
    setUnconfirmedShifts(prev =>
      prev.filter(sh => 
        !shiftDataArray.some(d => d.id === sh.id)
      )
    );
  } catch (err) {
    console.error('Error =>', err);
    alert('Error => ' + err.message);
  } finally {
	fetchEventsForRange(startDate, endDate);
    setIsVariantModalOpen(false);
  }
}


  // ─────────────────────────────────────────────────────────────
  // (D) MAIN SCHEDULE EVENTS
  // ─────────────────────────────────────────────────────────────
  useEffect(() => {
    if (!startDate || !endDate) return;
    fetchEventsForRange(startDate, endDate);
  }, [startDate, endDate]);

  async function fetchEventsForRange(start, end) {
    try {
      const payload = { StartDate: start, EndDate: end };
      const url = `${getBaseUrl()}/pfield_events`;
      const res = await axios.post(url, payload, { withCredentials: true });
      setEvents(res.data.result || []);
    } catch (err) {
      console.error('Error fetching events =>', err);
    }
  }

  // ─────────────────────────────────────────────────────────────
  // (E) DASHBOARD DATA => weekly + monthly
  // ─────────────────────────────────────────────────────────────

  async function fetchDashboardDataWithRange(weekStart, weekEnd, monthDate) {
    try {
      if (!storeData.length) return;

      const yyyy = monthDate.getFullYear();
      const mm = String(monthDate.getMonth() + 1).padStart(2, '0');

      const qs = new URLSearchParams({
        month: `${yyyy}-${mm}`,
        weeklyStart: weekStart,
        weeklyEnd:   weekEnd,
      }).toString();

      const url = `${getBaseUrl()}/pfield_events/pfield_dashboardData?${qs}`;
      const res = await axios.get(url, { withCredentials: true });

      let { weekly, monthly, cycleLength: newCycle } = res.data || {
        weekly: [],
        monthly: [],
        cycleLength: 3
      };

      weekly = mergeStoresIntoWeekly(weekly, storeData);
      monthly = mergeStoresIntoMonthly(monthly, storeData);

      // Ensure aggregator has an entry for every known store:
      const finalWeekly = [...weekly];
      storeData.forEach(sd => {
        const storeId = String(sd.store_id);
        const existing = finalWeekly.find(w => String(w.combined_id) === storeId);
        if (!existing) {
          finalWeekly.push({
            combined_id: storeId,
            storeName: sd.store_name || `Store#${storeId}`,
            scheduled: 0,
            required: 0,
            gap: 0,
          });
        }
      });

      setDashboardData({ weekly: finalWeekly, monthly });
      if (typeof newCycle === 'number') {
        setCycleLength(newCycle);
      }
    } catch (err) {
      console.error('Error fetching dashboard data =>', err);
      setDashboardData({ weekly: [], monthly: [] });
    }
  }

  async function fetchSchedulingPatterns() {
    try {
      const url = `${getBaseUrl()}/pfield_events/pfield_schedulingPatterns`;
      const res = await axios.get(url, { withCredentials: true });
      setPatternData(res.data);
    } catch (err) {
      console.error('Error fetching scheduling patterns =>', err);
      setPatternData({ patternsMap: {}, warnings: [] });
    }
  }

  useEffect(() => {
    if (storeData.length > 0) {
      fetchDashboardDataWithRange(weeklyRangeStart, weeklyRangeEnd, monthFocusDate);
      fetchSchedulingPatterns();
    }
  }, [storeData]);

  function handleEventsChanged() {
    // Re-fetch aggregator with monthFocusDate
    fetchDashboardDataWithRange(weeklyRangeStart, weeklyRangeEnd, monthFocusDate);
  console.log("[FOCUSED MONTH]", monthFocusDate);
    fetchSchedulingPatterns();
	fetchEventsForRange(startDate, endDate);
  }

  // ─────────────────────────────────────────────────────────────
  // (G) UI
  // ─────────────────────────────────────────────────────────────

  function toggleDashboard() {
    setDashboardCollapsed(prev => !prev);
  }

  function expandCollapseAndSwitchView(newView) {
    const wasCollapsed = dashboardCollapsed;
    setDashboardCollapsed(false);
    requestAnimationFrame(() => {
      scheduleSectionRef.current?.switchView?.(newView);
      requestAnimationFrame(() => {
        setDashboardCollapsed(wasCollapsed);
      });
    });
  }

function handleDateRangeChange(newStart, newEnd, newView, selDate) {
  const changed =
    newStart !== startDate ||
    newEnd !== endDate ||
    (newView && newView !== currentView);

  // Always track the schedule’s selected start/end (the exact date range being shown)
  setStartDate(newStart);
  setEndDate(newEnd);

  // Make local copies of our aggregator’s current state
  let localWeeklyStart = weeklyRangeStart;
  let localWeeklyEnd   = weeklyRangeEnd;
  let localMonthFocus  = monthFocusDate;

  if (newView) {
    // Sync up the "current view" in local state
    setCurrentView(newView);

    // ─────────────────────────────────────────────────────────
    // (1) If switching to TIMELINEWEEK:
    //     we can directly set localWeeklyStart/end from newStart/end
    //     and optionally update monthFocusDate
    // ─────────────────────────────────────────────────────────
    if (newView === 'TimelineWeek') {
      localWeeklyStart = newStart;
      localWeeklyEnd   = newEnd;
      setWeeklyRangeStart(localWeeklyStart);
      setWeeklyRangeEnd(localWeeklyEnd);

      // Optional: update monthFocusDate if we want the aggregator’s "month" to track the new start
      const newFocusDate = new Date(newStart); // the Monday in that week
      const oldMonth = localMonthFocus.getMonth();
      const oldYear  = localMonthFocus.getFullYear();
      const newMonth = newFocusDate.getMonth();
      const newYear  = newFocusDate.getFullYear();

      if (oldMonth !== newMonth || oldYear !== newYear) {
        localMonthFocus = newFocusDate;
        setMonthFocusDate(localMonthFocus);
      }
    }

    // ─────────────────────────────────────────────────────────
    // (2) If switching to TIMELINEDAY:
    //     compute that day’s Monday–Sunday to keep aggregator consistent
    //     and optionally update monthFocusDate
    // ─────────────────────────────────────────────────────────
    else if (newView === 'TimelineDay') {
      const { mondayStr, sundayStr } = computeMondaySunday(newStart);
      localWeeklyStart = mondayStr;
      localWeeklyEnd   = sundayStr;
      setWeeklyRangeStart(localWeeklyStart);
      setWeeklyRangeEnd(localWeeklyEnd);

      // Optional: update monthFocusDate if crossing a different month
      const newFocusDate = new Date(newStart);
      const oldMonth = localMonthFocus.getMonth();
      const oldYear  = localMonthFocus.getFullYear();
      const newMonth = newFocusDate.getMonth();
      const newYear  = newFocusDate.getFullYear();

      if (oldMonth !== newMonth || oldYear !== newYear) {
        localMonthFocus = newFocusDate;
        setMonthFocusDate(localMonthFocus);
      }
    }

    // ─────────────────────────────────────────────────────────
    // (3) If remaining in MONTH view (navigating forward/back):
    //     detect if we’re now in a different month. If so, pick
    //     the “first or last week” of that new month for aggregator
    //     and update the monthFocusDate accordingly
    // ─────────────────────────────────────────────────────────
    else if (currentView === 'Month' && newView === 'Month' && selDate) {
      // user navigated within Month => check if truly a new month
      const oldMonth = localMonthFocus.getMonth();
      const oldYear  = localMonthFocus.getFullYear();
      const newFocusDate = new Date(selDate);
      const newYear  = newFocusDate.getFullYear();
      const newMonth = newFocusDate.getMonth();

      if (oldYear !== newYear || oldMonth !== newMonth) {
        // Decide if user navigated forward or backward in the calendar
        if (newFocusDate > monthFocusDate) {
          // forward => use the first full Monday–Sunday in that new month
          const { mondayStr, sundayStr } = computeFirstWeekOfMonth(newYear, newMonth);
          localWeeklyStart = mondayStr;
          localWeeklyEnd   = sundayStr;
        } else {
          // backward => use the last full Monday–Sunday in that new month
          const { mondayStr, sundayStr } = computeLastWeekOfMonth(newYear, newMonth);
          localWeeklyStart = mondayStr;
          localWeeklyEnd   = sundayStr;
        }
        setWeeklyRangeStart(localWeeklyStart);
        setWeeklyRangeEnd(localWeeklyEnd);

        // Now update the "focus month"
        localMonthFocus = newFocusDate;
        setMonthFocusDate(localMonthFocus);
      }
    }
  }

  // If the date range or view changed, re-fetch aggregator/dash data
  if (changed) {
    fetchDashboardDataWithRange(localWeeklyStart, localWeeklyEnd, localMonthFocus);
  }
}

function handleWeekRangeSelected(mondayDate, sundayDate) {
  const mondayStr = formatLocalDateTime(mondayDate);
  const sundayStr = formatLocalDateTime(sundayDate);

  setWeeklyRangeStart(mondayStr);
  setWeeklyRangeEnd(sundayStr);

  fetchDashboardDataWithRange(mondayStr, sundayStr, monthFocusDate);
  console.log("[FOCUSED MONTH IN HWRS]", monthFocusDate);

  if (currentView === 'TimelineDay') {
    setCurrentView('TimelineWeek');
    setStartDate(mondayStr);
    setEndDate(sundayStr);
    scheduleSectionRef.current?.switchView('TimelineWeek');
    scheduleSectionRef.current?.setSelectedDate(mondayDate);
  }
	else if (currentView === 'Month') {
	  setCurrentView('TimelineWeek');
	  setStartDate(mondayStr);
	  setEndDate(sundayStr);
	  scheduleSectionRef.current?.switchView('TimelineWeek');
	  scheduleSectionRef.current?.setSelectedDate(mondayDate);
	}
  else {
    setStartDate(mondayStr);
    setEndDate(sundayStr);
    scheduleSectionRef.current?.setSelectedDate(mondayDate);
  }
}

  // ─────────────────────────────────────────────────────────────
  // (H) Prepare aggregator-based resourceHoursMap for the Overlay
  //     so it always shows full-week hours.
  // ─────────────────────────────────────────────────────────────
  const storeHoursMap = React.useMemo(() => {
    const map = {};
    // "weekly" array has each store's scheduled hours for the entire Monday–Sunday range
    dashboardData.weekly.forEach((row) => {
      // row => { combined_id, storeName, scheduled, required, gap }
      map[row.combined_id] = row.scheduled; 
    });
    return map;
  }, [dashboardData.weekly]);

  return (
    <div style={styles.pageContainer}>
      <DashboardSection
        collapsed={dashboardCollapsed}
        onToggle={toggleDashboard}
        monthlyData={dashboardData.monthly}
        cycleLength={cycleLength}
        patternWarnings={patternData.warnings}
        currentView={currentView}
        events={events}
        storeData={storeData}
        startDate={weeklyRangeStart}
        endDate={weeklyRangeEnd}
        onWeekSelected={handleWeekRangeSelected}
        weeklyData={dashboardData.weekly}
      />

      <ScheduleSection
        ref={scheduleSectionRef}
        storeData={storeData}
        events={events}
        setEvents={setEvents}
        shiftSuggestions={shiftSuggestions}
        startDate={startDate}
        endDate={endDate}
        onDateRangeChange={handleDateRangeChange}
        onEventsChanged={handleEventsChanged}
        fetchEventsForRange={fetchEventsForRange}

        // NEW PROP => aggregator-based map of store => weekly hours
        storeHoursMap={storeHoursMap}
      />

      <ChakraConfirmModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        shifts={unconfirmedShifts}
        onSave={handleConfirmModalSave}
        onVariantNeeded={handleVariantNeeded}
		forceClose={() => setIsConfirmModalOpen(false)}
      />

      <ChakraVariantModal
        isOpen={isVariantModalOpen}
        onClose={() => setIsVariantModalOpen(false)}
        variantShifts={variantShifts}
        storeData={storeData}
        onComplete={handleVariantComplete}
		forceCloseVariant={() => setIsVariantModalOpen(false)}
      />
    </div>
  );
}

const styles = {
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: 0.5,
    padding: 0.5,
  },
};
