// PopsReport.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { captureTableAndDownloadPDF } from "./generatePopsReportPDFv2";
import { captureTableAndDownloadExcel } from "./generatePopsReportExcelv2";
import { FaFilePdf, FaFileExcel } from "react-icons/fa";
import LoadingIndicator from "../../LoadingIndicator"; // Adjust the path as needed
// Chakra UI
import {
  Box,
  Flex,
  Text,
  Select,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import PopsReportTable from "./PopsReportTablev2";

// Helpers to format the month
function formatMonth(date) {
  return date.toLocaleString("default", { month: "long", year: "numeric" });
}
function parseSelectedMonth(selectedMonth) {
  const [monthName, year] = selectedMonth.split(" ");
  const monthIndex = new Date(`${monthName} 1, ${year}`).getMonth();
  return { monthIndex, yearInt: parseInt(year, 10) };
}
function getDaysInMonth(monthIndex, year) {
  return new Date(year, monthIndex + 1, 0).getDate();
}
function isCurrentMonthAndYear(selectedMonth) {
  const { monthIndex, yearInt } = parseSelectedMonth(selectedMonth);
  const today = new Date();
  return (
    monthIndex === today.getMonth() &&
    yearInt === today.getFullYear()
  );
}

function PopsReport() {
  // Month selection
  const [selectedMonth, setSelectedMonth] = useState(formatMonth(new Date()));
  // Logged-in user's role
  const [userGroup, setUserGroup] = useState("");
  // Director area selection
  const [selectedArea, setSelectedArea] = useState("Area Selection");
  const [directorAreas] = useState([
    "Area Selection",
    "Esch Group",
    "Northwest Arkansas",
    "Central Arkansas",
    "Tennessee",
    "St Louis",
    "Champaign",
    "Kansas & Nebraska",
  ]);

  // Loading and data states
  const [reportData, setReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [gradingStandards, setGradingStandards] = useState({});

  // Chakra UI theming
  const pageBg = useColorModeValue("gray.50", "gray.800");
  const boxBg = useColorModeValue("white", "gray.700");
  const borderCol = useColorModeValue("gray.200", "gray.600");

  // Build an array of the last 12 months
  const months = Array.from({ length: 12 }, (_, i) => {
    const d = new Date();
    d.setMonth(d.getMonth() - i);
    return formatMonth(d);
  }).reverse();

  // Helper to build base URL
  function getBaseUrl() {
    const hostname = window.location.hostname;
    if (hostname === "72.167.34.236") {
      return "http://72.167.34.236:5000";
    } else if (/^(www\.)?prolifi\.app$/.test(hostname)) {
      return "https://prolifi.app";
    } else {
      return "http://localhost:5000";
    }
  }

  // Fetch user info on mount
  useEffect(() => {
    const getUserGroupAndCompany = async () => {
      try {
        setIsLoading(true);
        const baseUrl = getBaseUrl();
        const response = await axios.get(`${baseUrl}/api/user-store`, {
          withCredentials: true,
        });
        const fetchedGroup = response.data.userGroup || "";
        setUserGroup(fetchedGroup);
        // For non-directors, you may load default grading standards
        if (fetchedGroup !== "director") {
          fetchGradingStandards();
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      } finally {
        setIsLoading(false);
      }
    };
    getUserGroupAndCompany();
  }, []);

  // Fetch grading standards based on selected area (or default)
  const fetchGradingStandards = async () => {
    let areaToFetch =
      !selectedArea || selectedArea === "Area Selection"
        ? "Default"
        : selectedArea;
    try {
      setIsLoading(true);
      const baseUrl = getBaseUrl();
      const encodedArea = encodeURIComponent(areaToFetch);
      const url = `${baseUrl}/api/grading-standards/${encodedArea}`;
      const response = await axios.get(url, { withCredentials: true });
      if (response.data) {
        setGradingStandards(response.data);
      } else {
        setGradingStandards({});
      }
    } catch (error) {
      console.error("Error fetching grading standards:", error);
      setGradingStandards({});
    } finally {
      setIsLoading(false);
    }
  };

  // Re-fetch standards when area or userGroup changes (if director)
  useEffect(() => {
    if (userGroup === "director") {
      fetchGradingStandards();
    }
    // eslint-disable-next-line
  }, [selectedArea, userGroup]);

  // Handle select changes
  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };
  const handleAreaChange = (e) => {
    setSelectedArea(e.target.value);
  };

  // MAIN fetch: use the final data from the back end to build the table
  useEffect(() => {
    // If director but no valid area selected, clear data
    if (
      userGroup === "director" &&
      (selectedArea === "Area Selection" || !selectedArea)
    ) {
      setReportData([]);
      return;
    }
    setReportData([]);
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const baseUrl = getBaseUrl();
        let url = `${baseUrl}/api/pops-route-v2/${selectedMonth}`;
        if (userGroup === "director") {
          url += `/${selectedArea}`;
        }
        const response = await axios.get(url, { withCredentials: true });
        // Assume the back end now returns final data (e.g., storeRanking) that you can use directly	
		console.log("KPI Thresholds:", response.data.kpiThresholds);
        console.log("FINAL STORE RANKING:", response.data.storeRanking);
		setGradingStandards(response.data.kpiThresholds || []);
        setReportData(response.data.storeRanking || []);
      } catch (err) {
        console.error("Error fetching pops data:", err);
        setReportData([]);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [selectedMonth, selectedArea, userGroup]);

  // PDF/Excel download handlers
  const handleDownloadPDF = async () => {
    setIsLoading(true);
    await captureTableAndDownloadPDF(reportData, selectedMonth, selectedArea, gradingStandards);
    setIsLoading(false);
  };
  const handleDownloadExcel = async () => {
    setIsLoading(true);
    await captureTableAndDownloadExcel(reportData, selectedMonth, selectedArea, gradingStandards);
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <Flex direction="column" bg={pageBg} minH="100vh" p={4} justify="center" align="center">
        <LoadingIndicator />
      </Flex>
    );
  }

  return (
    <Flex direction="column" bg={pageBg} minH="100vh" p={4}>
      {/* Controls */}
      <Flex mb={4} gap={4} wrap="wrap" align="center" borderWidth="1px" borderColor={borderCol} p={4} bg={boxBg} borderRadius="md" justifyContent="space-between">
        <Flex gap={6} align="center">
          <Box>
            <Text fontSize="sm" mb={1}>Select a Month:</Text>
            <Select value={selectedMonth} onChange={handleMonthChange} bg="white" color="black" size="sm">
              {months.map((m) => (
                <option key={m} value={m}>{m}</option>
              ))}
            </Select>
          </Box>
          {userGroup === "director" && (
            <Box>
              <Text fontSize="sm" mb={1}>Select an Area:</Text>
              <Select value={selectedArea} onChange={handleAreaChange} bg="white" color="black" size="sm">
                {directorAreas.map((a) => (
                  <option key={a} value={a}>{a}</option>
                ))}
              </Select>
            </Box>
          )}
        </Flex>
        <Flex gap={2}>
          <Button onClick={handleDownloadPDF} leftIcon={<FaFilePdf />} colorScheme="red" variant="outline" size="sm">PDF</Button>
          <Button onClick={handleDownloadExcel} leftIcon={<FaFileExcel />} colorScheme="green" variant="outline" size="sm">Excel</Button>
        </Flex>
      </Flex>
      {/* Table */}
      {(userGroup !== "director" || (gradingStandards && selectedArea !== "Area Selection")) ? (
        <Box borderWidth="1px" borderColor={borderCol} borderRadius="md" p={2} bg={boxBg}>
          <PopsReportTable data={reportData} />
        </Box>
      ) : (
        <Flex justify="center" mt={4}>
          <Text>Loading Grading Standards or waiting for area selection...</Text>
        </Flex>
      )}
    </Flex>
  );
}

export default PopsReport;
