/**
 * File: src/utils/shiftValidation.js
 *
 * validateShift handles:
 *   1) Checking if user is dragging onto the parent row (if so => cancel).
 *   2) Checking for overlapping shifts (Vacation vs. non-Vacation, store vs. store, Office/Meetings vs. anything).
 *   3) Disallowing shifts that cross midnight (must start and end on same calendar day).
 *
 * Returns an object: { cancel: boolean, message: string }
 *
 * Usage within DirectorScheduleSection:
 *   const { cancel, message } = validateShift(args, events, scheduleRef);
 *   if (cancel) {
 *     args.cancel = true;
 *     if (message) alert(message);
 *     return;
 *   }
 */

///////////////////////////////
// Inline helper functions:
///////////////////////////////

/** True if CombinedId is "Vacation" */
function isVacationId(id) {
  return id === 'Vacation';
}

/** True if CombinedId is a store (not Vacation, Office, or Meetings) */
function isStoreId(id) {
  return (
    id !== 'Vacation' &&
    id !== 'Office' &&
    id !== 'Meetings'
  );
}

/** True if CombinedId is either "Office" or "Meetings" */
function isStaticItem(id) {
  return (id === 'Office' || id === 'Meetings');
}

/**
 * doRangesOverlap => returns true if (startA..endA) overlaps (startB..endB).
 * i.e.: two time ranges (startA-endA) and (startB-endB) have any overlap.
 */
function doRangesOverlap(startA, endA, startB, endB) {
  return startA < endB && endA > startB;
}

/**
 * Main exported function: validateShift
 * Checks for:
 *   - Dropping onto a parent row
 *   - Single-day constraint (no crossing midnight)
 *   - Overlap constraints for Vacation, store, static items
 */
export function validateShift(args, events, scheduleRef) {
  console.log('validateShift => invoked with args:', args);

  const response = {
    cancel: false,
    message: '',
  };

  /////////////////////////////////////
  // 1) Check if user is dragging onto the parent row
  /////////////////////////////////////
  if (args.requestType === 'eventChange' && scheduleRef.current) {
    console.log("validateShift => eventChange => check if parent row");
    // Possibly the shift was dragged to a parent row in the resource hierarchy
    let changedRecord = Array.isArray(args.data) ? args.data[0] : args.data;
    if (changedRecord?.Guid) {
      const el = scheduleRef.current.element.querySelector(
        `[data-guid="${changedRecord.Guid}"]`
      );
      if (el) {
        const domGroupIndex = el.getAttribute('data-group-index');
        console.log('validateShift => domGroupIndex = ', domGroupIndex);

        if (domGroupIndex != null) {
          const numericIndex = parseInt(domGroupIndex, 10);
          const resourceHierarchy =
            scheduleRef.current.getResourcesByIndex(numericIndex);
          console.log('resourceHierarchy => ', resourceHierarchy);

          // If we see only 1 item, it might indicate a top-level parent row
          if (resourceHierarchy?.length === 1) {
            response.cancel = true;
            response.message = 'Cannot drop onto the parent row!';
            return response;
          }
        }
      }
    }
  }

  /////////////////////////////////////
  // 2) Overlap checks => eventChange / eventCreate
  //    + Single-day check
  /////////////////////////////////////
  if (
    args.requestType === 'eventChange' ||
    args.requestType === 'eventCreate'
  ) {
    // Identify the new or changed shift
    let newEvent = null;
    if (args.changedRecords?.length > 0) {
      newEvent = args.changedRecords[0];
    } else if (Array.isArray(args.data)) {
      newEvent = args.data[0];
    } else {
      newEvent = args.data;
    }

    if (!newEvent) return response;

    const newId         = newEvent.Id;
    const newCombinedId = newEvent.CombinedId;

    // The user might store time fields as "StartTime"/"EndTime" or "startTime"/"endTime"
    const startProp = newEvent.startTime || newEvent.StartTime;
    const endProp   = newEvent.endTime   || newEvent.EndTime;
    const newStart  = new Date(startProp);
    const newEnd    = new Date(endProp);

    // Basic sanity check => start < end
    if (newStart >= newEnd) {
      response.cancel = true;
      response.message = 'End time must be after start time.';
      return response;
    }

    // 2a) Single-day check => cannot cross midnight
    const startYear  = newStart.getFullYear();
    const startMonth = newStart.getMonth();
    const startDay   = newStart.getDate();

    const endYear  = newEnd.getFullYear();
    const endMonth = newEnd.getMonth();
    const endDay   = newEnd.getDate();

    if (
      startYear !== endYear ||
      startMonth !== endMonth ||
      startDay !== endDay
    ) {
      response.cancel = true;
      response.message = 'Cannot schedule a shift across multiple days.';
      return response;
    }

    // Overlap logic
    const newIsVac        = isVacationId(newCombinedId);
    const newIsStore      = isStoreId(newCombinedId);
    const newIsStaticItem = isStaticItem(newCombinedId); // Office or Meetings

    for (let ev of events) {
      // Skip if same SHIFT
      if (ev.Id === newId) continue;

      // Might store times as "startTime"/"endTime" or "StartTime"/"EndTime"
      const evStartProp = ev.startTime || ev.StartTime;
      const evEndProp   = ev.endTime   || ev.EndTime;

      const evStart = new Date(evStartProp);
      const evEnd   = new Date(evEndProp);

      // If the existing event is invalid, skip
      if (isNaN(evStart) || isNaN(evEnd)) {
        continue;
      }

      // If they do overlap in time
      if (doRangesOverlap(newStart, newEnd, evStart, evEnd)) {
        // The existing SHIFT’s type
        const evIsVac        = isVacationId(ev.CombinedId);
        const evIsStore      = isStoreId(ev.CombinedId);
        const evIsStaticItem = isStaticItem(ev.CombinedId);

        /**
         * Vacation vs. non-Vacation
         */
        if ((newIsVac && !evIsVac) || (!newIsVac && evIsVac)) {
          response.cancel = true;
          response.message =
            'Cannot schedule overlap between Vacation and non-Vacation shifts.';
          return response;
        }

        /**
         * Store vs store
         * (i.e., if both are store => block overlap)
         */
        if (newIsStore && evIsStore) {
          response.cancel = true;
          response.message =
            'Cannot schedule store shift: overlapping another store shift.';
          return response;
        }

        /**
         * Office/Meetings => disallow overlap with any SHIFT
         * (The user said static items should NOT be allowed to overlap either)
         */
        if (newIsStaticItem || evIsStaticItem) {
          response.cancel = true;
          response.message =
            'Cannot schedule overlapping Office/Meetings shift.';
          return response;
        }
      }
    }
  }

  return response;
}
