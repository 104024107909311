// GradeKPIsv2.js
const gradeGenericKPI = (value, thresholds, options = {}) => {
  const { higherBetter = true } = options;
  // Force thresholds to numbers.
  const greenThreshold = Number(thresholds.greenThreshold);
  const redThreshold = Number(thresholds.redThreshold);

  // If thresholds are missing or invalid, default to green.
  if (thresholds == null || isNaN(greenThreshold) || isNaN(redThreshold)) {
    console.log(`[gradeGenericKPI] Missing or invalid thresholds, defaulting to green for value: ${value}`);
    return { color: 'green', points: 1 };
  }

  console.log(`[gradeGenericKPI] value: ${value}, greenThreshold: ${greenThreshold}, redThreshold: ${redThreshold}, higherBetter: ${higherBetter}`);

  let color;
  if (higherBetter) {
    if (value >= greenThreshold) {
      color = 'green';
      console.log(`[gradeGenericKPI] ${value} >= ${greenThreshold} → green`);
    } else if (value >= redThreshold) {
      color = 'yellow';
      console.log(`[gradeGenericKPI] ${value} < ${greenThreshold} but >= ${redThreshold} → yellow`);
    } else {
      color = 'red';
      console.log(`[gradeGenericKPI] ${value} < ${redThreshold} → red`);
    }
  } else {
    if (value <= greenThreshold) {
      color = 'green';
      console.log(`[gradeGenericKPI] ${value} <= ${greenThreshold} → green`);
    } else if (value <= redThreshold) {
      color = 'yellow';
      console.log(`[gradeGenericKPI] ${value} > ${greenThreshold} but <= ${redThreshold} → yellow`);
    } else {
      color = 'red';
      console.log(`[gradeGenericKPI] ${value} > ${redThreshold} → red`);
    }
  }

  let multiplier;
  if (color === 'green') {
    multiplier = 1;
  } else if (color === 'yellow') {
    multiplier = 0.5;
  } else if (color === 'red') {
    multiplier = -0.25;
  }
  console.log(`[gradeGenericKPI] Final grade: ${color} with multiplier ${multiplier}`);

  return { color, points: multiplier };
};

const gradeKPI = {
  salesPctChange: (value, thresholds) => gradeGenericKPI(value, thresholds, { higherBetter: true }),
  contExpenses: (value, thresholds) => gradeGenericKPI(value, thresholds, { higherBetter: false }),
  laborPct: (value, thresholds) => gradeGenericKPI(value, thresholds, { higherBetter: false }),
  crewProduction: (value, thresholds) => gradeGenericKPI(value, thresholds, { higherBetter: true }),
  onTimesPct: (value, thresholds) => gradeGenericKPI(value, thresholds, { higherBetter: true }),
  // For foodVariance, higher values are better (so 0.02 is green, while -2.25 is red).
  foodVariance: (value, thresholds) => gradeGenericKPI(value, thresholds, { higherBetter: true }),
  avgTime: (value, thresholds) => gradeGenericKPI(value, thresholds, { higherBetter: false }),
  replyTime: (value, thresholds) => gradeGenericKPI(value, thresholds, { higherBetter: false }),
  complaintsPer10K: (value, thresholds) => gradeGenericKPI(value, thresholds, { higherBetter: false }),
  cash: (value, thresholds) => gradeGenericKPI(value, thresholds, { higherBetter: true }),
};

module.exports = gradeKPI;
