// src/Cr80CardPrinter.js
import React from "react";
import QRGuts from "./QRGuts";
import RightPanel from "./RightPanel"; // import the new component

/**
 * This high-level component renders both QRGuts and RightPanel side-by-side.
 */
function Cr80CardPrinter() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        padding: "20px",
      }}
    >
      {/* Left container with QRGuts */}
      <div style={{ flex: 1 }}>
        <QRGuts />
      </div>

      {/* Right container with RightPanel */}
      <div style={{ width: "300px", marginLeft: "20px" }}>
        <RightPanel />
      </div>
    </div>
  );
}

export default Cr80CardPrinter;
