import React from "react";
import { QRCode } from "react-qrcode-logo";

/**
 * Renders a QR code (react-qrcode-logo) with optional embedded logo, eye colors, etc.
 */
function QrCodeDisplay({
  qrLink,
  size,
  bgColor,
  fgColor,
  ecLevel,
  qrStyle,
  eyeColor,
  eyeRadius,
  logoUrl,
  logoWidth,
  removeQrBg,
}) {
  return (
    <QRCode
      value={qrLink || ""}
      size={size || 300}
      bgColor={bgColor || "#ffffff"}
      fgColor={fgColor || "#000000"}
      ecLevel={ecLevel || "H"}
      qrStyle={qrStyle || "dots"}
      eyeColor={eyeColor}
      eyeRadius={eyeRadius}
      logoImage={logoUrl || undefined}
      logoWidth={logoWidth || 100}
      removeQrCodeBehindLogo={removeQrBg || false}
    />
  );
}

export default QrCodeDisplay;
