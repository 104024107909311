import React from "react";

/**
 * A simple container that represents a CR80 card size.
 * CR80 dimensions are 3.375" x 2.125".
 * Using inches directly can help with printing scale.
 */
function Cr80Card({ children }) {
  return (
    <div
      style={{
        width: "3.375in",
        height: "2.125in",
        border: "none",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {children}
    </div>
  );
}

export default Cr80Card;
